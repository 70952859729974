/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../../WithMobile';
import * as functions from '../../functions';

//import Image from '../../../images/condo_exterior/BuildingExterior.svg';
import Image from '../../../images/condo_exterior/BuildingExterior.min.svg';

import { Redirect } from 'react-router'
import Landscape from '../../../images/mobile_svgs/condo/landscape.svg';
import Roof from '../../../images/mobile_svgs/condo/roof.svg';
import Structure from '../../../images/mobile_svgs/condo/structure.svg';
import Exterior from '../../../images/mobile_svgs/condo/exterior_1.svg';
import Windows from '../../../images/mobile_svgs/condo/windows.svg';
import Balcony from '../../../images/mobile_svgs/condo/balcony.svg';


const Colors = [['landscaping', false], ['roof', false], ['structure', false],
    ['fire_safety', false], ['exterior_closure', false], ['balcony', false], ['ce_windows', false]
];
const title = "Select a view"
const navItems = [
    {
        path: "building_exterior/landscaping",
        title: "Landscaping",
        class: "landscaping",
        highlight_mode: 2
    },
    {
        path: "building_exterior/roof",
        title: "Roof",
        class: "roof",
        highlight_mode: 2
    },
    {
        path: "building_exterior/balcony",
        title: "Balcony",
        class: "balcony",
        highlight_mode: 2
    },
    {
        path: "building_exterior/structure",
        title: "Structure",
        class: "structure",
        highlight_mode: 2
    },
    {
        path: "building_exterior/ce_windows",
        title: "Windows",
        class: "ce_windows",
        highlight_mode: 2
    },
    {
        path: "building_exterior/exterior_closure",
        title: "Exterior Closure",
        class: "exterior_closure",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "building_exterior/" + item });
        }
    }

    onMobileClick(item)
    {
        this.setState({ redirect: true, redirectTo: item });
    }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ? 
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors, 2))} onClick={(e) => this.onMouseClick(e)} className="house tarion-cpg__svg-container pointer">
                    <Image />
                </div>
            </div>:
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('building_exterior/landscaping')} className="tarion-cpg__svg-container">
                    <Landscape />
                    <p>Landscaping</p>
                </div>
                <div onClick={(e) => this.onMobileClick('building_exterior/roof')} className="tarion-cpg__svg-container">
                    <Roof />
                    <p>Roof</p>
                </div>
                <div onClick={(e) => this.onMobileClick('building_exterior/ce_windows')} className="tarion-cpg__svg-container">
                    <Windows />
                    <p>Windows</p>
                </div>
                <div onClick={(e) => this.onMobileClick('building_exterior/structure')} className="tarion-cpg__svg-container">
                    <Structure />
                    <p>Structure</p>
                </div>
                <div onClick={(e) => this.onMobileClick('building_exterior/balcony')} className="tarion-cpg__svg-container">
                    <Balcony />
                    <p>Balcony</p>
                </div>
                <div onClick={(e) => this.onMobileClick('building_exterior/exterior_closure')} className="tarion-cpg__svg-container">
                    <Exterior />
                    <p>Exterior Closure</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}


export default Container;