/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../../WithMobile';
import * as functions from '../../functions';

//import CondoImage from '../../../images/townhome/building_exterior/exterior.svg'
//import CondoImage from '../../../images/CondoTownhome.svg'
import CondoImage from '../../../images/CondoTownhome.min.svg'


import Roof from '../../../images/mobile_svgs/condo/roof.svg';
import Land from '../../../images/mobile_svgs/landscape.svg';
import Fire from '../../../images/mobile_svgs/condo/fire-safety.svg';
import Balcony from '../../../images/mobile_svgs/condo/balcony.svg';
import Structure from '../../../images/mobile_svgs/condo/structure.svg';
import MobFoundation from '../../../images/mobile_svgs/Foundation.svg';
import MobElectrical from '../../../images/mobile_svgs/electrical.svg';
import MobCladding from '../../../images/mobile_svgs/Siding.svg';
import MobPaint from '../../../images/mobile_svgs/Paint.svg';
import MobSteps from '../../../images/mobile_svgs/Landing-steps.svg';
import MobWindows from '../../../images/mobile_svgs/Windows.svg';
import MobColumn from '../../../images/mobile_svgs/Column.svg';
import MobDoors from '../../../images/mobile_svgs/Doors.svg';
import MobGarage from '../../../images/mobile_svgs/Garage.svg';

import { Redirect } from 'react-router'

const Colors = [['landscaping', false], ['roof', false], ['fire_safety', false],
   ['balcony', false], ['structure', false], ['electrical', false], ['exterior_cladding', false],
    ['exterior_paint', false], ['foundation', false], ['landing', false], ['exterior_windows', false], 
    ['exterior_columns', false], ['exterior_doors_man', false], ['exterior_doors', false]
];
const title = "Select a view"
const navItems = [
    {
        path: "townhome/building_exterior/fire_safety",
        title: "Fire Safety",
        class: "fire_safety",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/landscaping",
        title: "Landscaping",
        class: "landscaping",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/roof",
        title: "Roof",
        class: "roof",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/structure",
        title: "Structure",
        class: "structure",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/balcony",
        title: "Balcony",
        class: "balcony",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/electrical",
        title: "Electrical",
        class: "electrical",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/exterior_cladding",
        title: "Exterior Cladding",
        class: "exterior_cladding",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/exterior_paint",
        title: "Exterior Paint",
        class: "exterior_paint",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/foundation",
        title: "Foundation",
        class: "foundation",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/landing",
        title: "Landing or Steps",
        class: "landing",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/exterior_windows",
        title: "Exterior Windows",
        class: "exterior_windows",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/exterior_columns",
        title: "Exterior Columns",
        class: "exterior_columns",
        highlight_mode: 2
    },
    {
        path: "townhome/building_exterior/exterior_doors",
        title: "Exterior Doors",
        id: "exterior_doors3",
        mode_selected: "interior",
        class: "exterior_doors exterior_doors_man",
        highlight_mode: 2
    }
]


class CondoExterior extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "townhome/building_exterior/" + item });
        }
    }

    onMobileClick(item)
    {
        this.setState({ redirect: true, redirectTo: item });
    }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors, 2))} onClick={(e) => this.onMouseClick(e)} className="house tarion-cpg__svg-container pointer">
                    <CondoImage />
                </div>
            </div> :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('townhome/building_exterior/fire_safety')} className="tarion-cpg__svg-container">
                    <Fire />
                    <p>Fire Safety</p>
                </div>
                <div onClick={(e) => this.onMobileClick('townhome/building_exterior/landscaping')} className="tarion-cpg__svg-container">
                    <Land />
                    <p>Landscaping</p>
                </div>
                <div onClick={(e) => this.onMobileClick('townhome/building_exterior/roof')} className="tarion-cpg__svg-container">
                    <Roof />
                    <p>Roof</p>
                </div>
                <div onClick={(e) => this.onMobileClick('townhome/building_exterior/structure')} className="tarion-cpg__svg-container">
                    <Structure />
                    <p>Structure</p>
                </div>
                <div onClick={(e) => this.onMobileClick('townhome/building_exterior/balcony')} className="tarion-cpg__svg-container">
                    <Balcony />
                    <p>Balcony</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_columns')} className="tarion-cpg__svg-container">
                    <MobColumn />
                    <p>Exterior Columns</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_windows')} className="tarion-cpg__svg-container">
                    <MobWindows />
                    <p>Exterior Windows</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_cladding')} className="tarion-cpg__svg-container">
                    <MobCladding />
                    <p>Exterior Cladding</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_doors')} className="tarion-cpg__svg-container">
                    <MobDoors />
                    <p>Exterior Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/foundation')} className="tarion-cpg__svg-container">
                    <MobFoundation />
                    <p>Foundation</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_doors_man')} className="tarion-cpg__svg-container">
                    <MobGarage />
                    <p>Garage & Man Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/landing')} className="tarion-cpg__svg-container">
                    <MobSteps />
                    <p>Landing or Steps</p>
                </div>

                <div onClick={(e) => this.onMobileClick('exterior/exterior_paint')} className="tarion-cpg__svg-container">
                    <MobPaint />
                    <p>Exterior Paint</p>
                </div>
                
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}


export default CondoExterior;