/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import BreadcrumbTrail from './BreadcrumbTrail'
import Logo from '../images/logo.svg';
import SearchForm from './SearchForm'
import BackButton from './BackButton';

const Header = () => (
    <header id="tarion-cpg__header">
        <div className="header_logo">
            <a href="https://tarion.com/">
                <Logo className="img" />
            </a>
            <h1>Home Explorer
                <span id="h2">The illustrated version of the Construction Performance Guidelines</span>
            </h1>
        </div>
        <div className="header_crumb_search">
            <BackButton />
            <BreadcrumbTrail />
            <SearchForm />
        </div>
    </header>
)

export default Header