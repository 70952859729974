import React from 'react';
import * as functions from './functions';

class ModeLink extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            modalVis: false
        }
    }

    handleClick(evt){
        evt.persist();
        if(!this.state.modalVis)
        {
            let item = document.getElementById(this.props.item.id);
            let item2 = document.getElementById(this.props.item.id + "_click");
            this.setState({modalVis: !this.state.modalVis});
            item.style.display = "block";
            item.style.top = (evt.clientY) + "px";
            item.style.left = (item2.offsetLeft + item2.offsetWidth + 40) + "px";
        }
        else
        {
            let item = document.getElementById(this.props.item.id);
            this.setState({ modalVis: !this.state.modalVis });
            item.style.display = "none";
            item.style.top = "-1000px";
            item.style.left = "-1000px";
        }
        evt.stopPropagation();
    }

    render(){

        let id = this.props.item.id + "_click";
        let f = "arrows " + this.props.item.class;
        let u = "a1 " + this.props.item.class;

        //console.log(this.props);

        return (
            <li onMouseMove={((e) => functions.default.onMove(e, this.props.Colors, this.props.item.highlight_mode))} id={id} className={this.props.item.class ? this.props.item.class + " mobile_hide" : "mobile_hide"}>
                <p className={this.props.item.class ? this.props.item.class + " p_link" : "p_link"} onClick={(e) => this.handleClick(e)}>{this.props.item.title}</p>
                <svg onClick={(e) => this.handleClick(e)} role="presentation" className={f} fill="black" height="44" viewBox="0 0 45 45" width="44" xmlns="http://www.w3.org/2000/svg">
                    <path className={u} d="M10,20 L20,27"></path>
                    <path className={u} d="M20,27 L30,20"></path>
                </svg>
            </li>
        );
    }
}

export default ModeLink;