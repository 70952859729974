import React from 'react'
import BackButton from './BackButton'
import { Link } from 'react-router-dom'
//import def_img from '../images/image.png';

const Category = (
    title,
    navItems,
    showBackButton = true,    
    showGeneral = false,

) => ({ ...props }) => {

    const Items = navItems.map((item,i) => (
        <Link key={i} className="category_detail" to={item.path}>
            <div>
                {item.image ? <item.image/> : ""}
                <p>{item.title}</p>
            </div>
        </Link>
    ));

    let articleName = navItems[0].path.split("/")[0] + "/general";

    if(showGeneral)
        Items.unshift(
            <Link key="-1" className="category_detail" to={articleName}>
                <div>
                   {/*<img src={require('../images/image.png')} alt="test" />*/}
                    <p>General</p>
                </div>
            </Link>
        );

    return (
        <div className="tarion-cpg-category_container">
            <p className="category_back_button">
                {showBackButton ? <BackButton /> : ""}
            </p>
            <h1 className="category_header">                
                {title}
            </h1>            
            <div className="tarion_cpg_categories">
                {Items}
            </div>    
        </div>
    )
}

export default Category