/* eslint import/no-webpack-loader-syntax: off */
import Category from '../../Category'

import ExtD from '../../../images/mobile_svgs/exterior-door.svg';
import Cabinets from '../../../images/mobile_svgs/cabinets.svg';
import Countertop from '../../../images/mobile_svgs/countertop.svg';
import Ceiling from '../../../images/mobile_svgs/ceiling.svg';
import IntD from '../../../images/mobile_svgs/interior-door.svg';
import Floor from '../../../images/mobile_svgs/flooring.svg';
import Trim from '../../../images/mobile_svgs/trim.svg';
import Wall from '../../../images/mobile_svgs/walls.svg';

const title = "Select Interior Finishes Category"
const navItems = [
    {
        path: "interior_finishes/cabinets",
        title: "Cabinets",
        image: Cabinets
    },
    {
        path: "interior_finishes/ceiling",
        title: "Ceiling",
        image: Ceiling
    },
    {
        path: "interior_finishes/countertops",
        title: "Countertops",
        image: Countertop
    },
    {
        path: "interior_finishes/interior_doors",
        title: "Interior Doors",
        image: IntD
    },
    {
        path: "interior_finishes/exterior_doors",
        title: "Exterior Doors",
        image: ExtD
    },
    {
        path: "interior_finishes/flooring",
        title: "Flooring",
        image: Floor
    },
    {
        path: "interior_finishes/trim",
        title: "Trim",
        image: Trim
    },
    {
        path: "interior_finishes/wall",
        title: "Wall",
        image: Wall
    }
]

export default Category(title, navItems, true, false)