import React from 'react'
import WithSidebar from './withSidebar';
import { SlideDown } from 'react-slidedown';

class WithMobile extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = { hide : true};
    }

    render()
    {
        if(!this.props.showDesktop)
        {
            return(
                <div className="tarion-cpg__mobile">
                    {this.props.title && <h2 className="tarion-cpg__mobile-header">{this.props.title}</h2>}
                    {this.props.Paragraph ? <h5 onClick={() => this.setState({hide: !this.state.hide})} className="blue">Need Help?</h5> : ""}
                    <SlideDown closed={this.state.hide}>{this.props.Paragraph}</SlideDown>
                    {this.props.Component }
                </div>
            );
        }
        else
        {
            return (
                <WithSidebar Component={this.props.Component} title={this.props.title} 
                    showBackButton={this.props.showBackButton}
                    navItems={this.props.navItems}
                    Colors={this.props.Colors}
                    Paragraph={this.props.Paragraph}/>
            )
        }
    }
}

export default WithMobile;