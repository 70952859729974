/* eslint import/no-webpack-loader-syntax: off */
import Category from '../Category'

const title = "Select Exterior Cladding Category"
const navItems = [
    {
        path: "exterior_cladding/masonry",
        title: "Masonry"
    },
    {
        path: "exterior_cladding/aluminum_vinyl",
        title: "Aluminum / Vinyl"
    },
    {
        path: "exterior_cladding/stucco",
        title: "Stucco"
    },
    {
        path: "exterior_cladding/wood",
        title: "Wood Siding"
    }
]

export default Category(title, navItems, true, false)