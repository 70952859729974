import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

class BackButton extends Component
{
    render()
    {
        const { fillColour, history } = this.props

        if (this.props.location.pathname === "/" && window.innerWidth <= 980)
        {
            return ("")
        }
        else
        {
            return (
                <button className="tarion-cpg__back-btn" onClick={() => history.back()}>
                    <span>Back</span>
                    <svg role="presentation" fill={fillColour} height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                        <path d="M0-.5h24v24H0z" fill="none" />
                    </svg>
                </button>
            )
        }
    }
}

BackButton.propTypes = {
    fillColour: PropTypes.string,
    history: PropTypes.object.isRequired
}

BackButton.defaultProps = {
    fillColour: "#000000"
}

export default withRouter(BackButton)