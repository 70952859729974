/* eslint import/no-webpack-loader-syntax: off */
import Category from '../Category'

import Carpet from '../../images/mobile_svgs/carpet.svg';
import Hardwood from '../../images/mobile_svgs/hardwood.svg';
import Vinyl from '../../images/mobile_svgs/vinyl.svg';
import Laminate from '../../images/mobile_svgs/laminate.svg';
import Tile from '../../images/mobile_svgs/tile.svg';

const title = "Select Flooring Category"
const navItems = [
    {
        path: "flooring/tile_ceramic",
        title: "Tile or Ceramic",
        image: Tile
    },
    {
        path: "flooring/vinyl",
        title: "Vinyl",
        image: Vinyl
    },
    {
        path: "flooring/carpet",
        title: "Carpet",
        image: Carpet
    },
    {
        path: "flooring/hardwood_parquet",
        title: "Hardwood or Parquet",
        image: Hardwood
    },
    {
        path: "flooring/laminate",
        title: "Laminate",
        image: Laminate
    },
]

export default Category(title, navItems, true, true)