/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../../WithMobile';
import * as functions from '../../functions';

import Image from '../../../images/condo_exterior/GarbageRecycling.svg';
import { Redirect } from 'react-router'
import Structure from '../../../images/mobile_svgs/condo/structure.svg';
import Finishes from '../../../images/mobile_svgs/condo/interior-finishes.svg';
import Fire from '../../../images/mobile_svgs/condo/fire-safety.svg';
import Access from '../../../images/mobile_svgs/condo/barrier2.svg';
import Electrical from '../../../images/mobile_svgs/condo/electrical_1.svg';
import Plumbing from '../../../images/mobile_svgs/condo/plumbing2.svg';
import Mechanical from '../../../images/mobile_svgs/condo/mechanical.svg';


const Colors = [['structure', false], ['electrical', false], ['barrier_free', false],
    ['plumbing', false], ['interior_finishes', false], ['fire_safety', false]
];
const title = "Select a view"
const navItems = [
    {
        path: "garbage_recycling/plumbing",
        title: "Plumbing",
        class: "plumbing",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/electrical",
        title: "Electrical",
        class: "electrical",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/barrier_free",
        title: "Barrier Free Access",
        class: "barrier_free",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/fire_safety",
        title: "Fire Safety",
        class: "fire_safety",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/mechanical",
        title: "Mechanical",
        class: "mechanical",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/interior_finishes",
        title: "Interior Finishes",
        class: "interior_finishes",
        highlight_mode: 2
    },
    {
        path: "garbage_recycling/structure",
        title: "Structure",
        class: "structure",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "garbage_recycling/" + item });
        }
    }

    onMobileClick(item)
    {
        this.setState({ redirect: true, redirectTo: item });
    }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ? 
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors, 2))} onClick={(e) => this.onMouseClick(e)} className="house tarion-cpg__svg-container pointer">
                    <Image />
                </div>
            </div>:
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('garbage_recycling/plumbing')} className="tarion-cpg__svg-container">
                    <Plumbing />
                    <p>Plumbing</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/electrical')} className="tarion-cpg__svg-container">
                    <Electrical />
                    <p>Electrical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/barrier_free')} className="tarion-cpg__svg-container">
                    <Access />
                    <p>Barrier Free Access</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/fire_safety')} className="tarion-cpg__svg-container">
                    <Fire />
                    <p>Fire Safety</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/mechanical')} className="tarion-cpg__svg-container">
                    <Mechanical />
                    <p>Mechanical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/interior_finishes')} className="tarion-cpg__svg-container">
                    <Finishes />
                    <p>Interior Finishes</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garbage_recycling/structure')} className="tarion-cpg__svg-container">
                    <Structure />
                    <p>Structure</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}


export default Container;