/* eslint import/no-webpack-loader-syntax: off */
/* eslint-disable  react/jsx-no-target-blank */

import React from 'react'
import WithMobile from './WithMobile'

//import Outside from '../images/Condos.svg'
import Outside from '../images/Condos.min.svg'

import MobCondoImage1 from '../images/mobile_svgs/condo-interior.svg';
import MobCondoImage2 from '../images/mobile_svgs/condo.svg';
import MobCondoImage3 from '../images/mobile_svgs/condo/townhome.svg';

import { Redirect } from 'react-router'
import * as functions from './functions';

const Colors = [['condo_unit', false], ['townhome_unit', false], ['common_element', false], ['townhome', false]];
const title = "Select a view"
const navItems = [
    {
        path: "/condo/condo_unit",
        title: "Condominium Unit",
        class: "condo_unit",
        highlight_mode: 2
    },
    {
        path: "/condo/common_element",
        title: "Condominium Common Element",
        class: "common_element",
        highlight_mode: 2
    },
    {
        path: "/condo/townhome_unit",
        title: "Condominium Townhome Unit",
        class: "townhome_unit",
        highlight_mode: 2
    },
    {
        path: "/condo/townhome",
        title: "Condominium Townhome",
        class: "townhome",
        highlight_mode: 2
    }
]

const Paragraph = <div className="explain">
                    <p>Refer to the Disclosure Package that was provided with your Agreement of Purchase and Sale or the registered Declaration and Description to find the unit/common element boundaries. You may also speak to your condominium board or condominium manager to find where a potential deficiency falls under.
                    </p>

                    <p>
                        Condominium projects come with two separate warranties: one that covers units and one that covers common elements. 
                        A unit owner can make warranty claims for deficiencies in their unit, and the condominium board can make warranty claims for deficiencies in the common elements. 
                        The unit warranty begins when the unit is ready for occupancy. 
                        The common elements warranty begins when the condominium is registered.
                    </p>

                    <p>Common Elements refer to all the property in a residential condominium building except the units. 
                        They include areas and systems that are shared by unit owners, such as lobbies, parking garages, recreational facilities (e.g., pools and gyms), exclusive use areas (e.g., balconies), and heating and electrical systems. 
                        If you observe a deficiency but are not sure whether it is in your unit or part of the common elements, review the unit boundaries set out in the Declaration and Description for the building or speak to your condominium board or condominium manager.
                    </p> 
                    
                    <p>You can find more information about the common elements warranty&nbsp;
                        <a rel="noopener" target="_blank" href="https://tarion.com/homeowners/your-warranty-coverage/condominium-common-elements">here</a> 
                        &nbsp;and in these videos -&nbsp;
                        <a rel="noopener" target="_blank" href="https://www.youtube.com/watch?v=M4QCzwPWH2w">"The New Home Warranty for Condominiums"</a> and&nbsp; 
                        <a rel="noopener" target="_blank" href="https://www.youtube.com/watch?v=gGdE7TjKMEg">"Protecting Your Investment - Common Elements"</a>
                    </p>
                </div>

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "condo/" + item });
        }
    }

    onMobileClick(item)
    {
        this.setState({ redirect: true, redirectTo: item });
    }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
                <div onMouseMove={((e) => functions.default.onMove(e, Colors, 2))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <Outside />
                </div>
           :
           <div className="tarion-cpg__mobile-container">
                <div onClick={(e) => this.onMobileClick('condo/condo_unit')} className="tarion-cpg__svg-container">
                    <MobCondoImage1 />
                    <p>Condominium Unit</p>
                </div>
                <div onClick={(e) => this.onMobileClick('condo/common_element')} className="tarion-cpg__svg-container">
                    <MobCondoImage2 />
                    <p>Condominium Common Elements</p>
                </div>
                <div onClick={(e) => this.onMobileClick('condo/townhome_unit')} className="tarion-cpg__svg-container">
                    <MobCondoImage1 />
                    <p>Townhome Unit</p>
                </div>
                <div onClick={(e) => this.onMobileClick('condo/townhome')} className="tarion-cpg__svg-container">
                    <MobCondoImage3 />
                    <p>Condominium Townhome</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>
        
        return (
            <WithMobile Paragraph={Paragraph} showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container;// withSidebar(Container, title, true, navItems, Colors)