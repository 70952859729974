import React, { Component } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import Header from './components/Header'
import NotFound from './components/NotFound'
import StartContainer from './components/StartContainer'
import HouseContainer from './components/HouseContainer'
import CondoContainer from './components/CondoContainer'
import InteriorContainer from './components/interior/InteriorContainer'
import Bedroom from './components/interior/Bedroom'
import ExteriorContainer from './components/exterior/ExteriorContainer'
import SearchContainer from './components/SearchContainer'
import Floor from './components/interior/Floor';
import Floor2 from './components/interior/FloorNoVinyl';
import Bathroom from './components/interior/Bathroom';
import LivingRoom from './components/interior/LivingRoom';
import Kitchen from './components/interior/Kitchen';
import Article from './components/articles/Article';
import Laundry from './components/interior/Laundry';
import BasementUnfin from './components/interior/BasementUnfin';
import BasementFin from './components/interior/BasementFin';
import Roof from './components/exterior/Roof';
import Siding from './components/exterior/Siding';
import Landscape from './components/exterior/Landscape';
import CondoUnit from './components/condo/CondoUnit';
import CommonArea from './components/condo/CommonArea';
import Cold from './components/interior/Cold';
import Hallway from './components/interior/Hallway';
import Garage from './components/exterior/Garage';
import CondoBathroom from './components/condo/CondoBathroom';
import CondoKitchen from './components/condo/CondoKitchen';
import CondoBedroom from './components/condo/CondoBedroom';
import CondoLivingRoom from './components/condo/CondoLivingRoom';
import CondoLaundry from './components/condo/CondoLaundry';
import CondoEntry from './components/condo/CondoEntry';
import BuildingExterior from './components/condo/CommonElements/BuildingExterior';
import Corridors from './components/condo/CommonElements/Corridors';
import ParkingGarage from './components/condo/CommonElements/ParkingGarage';
import GarbageRecycling from './components/condo/CommonElements/GarbageRecycling';
import GroundLobby from './components/condo/CommonElements/GroundLobby';
import PoolArea from './components/condo/CommonElements/PoolArea';
import UtilitiesRoom from './components/condo/CommonElements/UtilitiesRoom';
import CondoExterior from './components/condo/townhome/CondoExterior';
import Exterior from './components/condo/CommonElements/Exterior';
import InteriorFinishesGarbage from './components/condo/CommonElements/InteriorFinishesGarbage';
import InteriorFinishes from './components/condo/CommonElements/InteriorFinishes';
import InteriorFinishesAmenity from './components/condo/CommonElements/InteriorFinishesAmenity';
import InteriorFinishesPool from './components/condo/CommonElements/InteriorFinishesPool';

import ExteriorDoors from './components/condo/CommonElements/ExteriorDoors';
import Amenity from './components/condo/CommonElements/AmenityRoom';

// Google analytics
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';
ReactGA.initialize('UA-38457621-8',{
	debug: false,
	titleCase: false
});

//saved the value of ReactGA as it was not available in the history function in Edge.
const ga = ReactGA;
const history = createBrowserHistory();
history.listen(({ location }) => {
	ga.set({ page: location.pathname });
	ga.pageview(location.pathname);
});

export default class App extends Component
{
    closeModal()
    {
        let found = Array.from(document.querySelectorAll(".modal"));
        for (var i = 0; i < found.length; i++)
            found[i].style.display = "none";
    }

    render()
    {
        return (
			<Router history={history}>
                <div id="tarion-cpg__app" onClick={(e) => this.closeModal()}>
                    <Header />
                    <div id="tarion-cpg__main">
                        <Switch>
                            <Route exact path="/" component={StartContainer} />
                            <Route exact path="/freehold" component={HouseContainer} />
                            <Route exact path="/condo" component={CondoContainer} />
                            <Route exact path="/article/:id" component={Article} />

                            { /* Interior Routes */ }
                            <Route exact path="/freehold/interior" component={InteriorContainer} />
                            <Route exact path="/freehold/*/bedroom" component={Bedroom} />
                            <Route exact path="/*/*/attic" component={Article} />
                            <Route exact path="/freehold/*/bathroom" component={Bathroom} />
                            <Route exact path="/freehold/*/kitchen" component={Kitchen} />
                            <Route exact path="/freehold/*/living_room" component={LivingRoom} />
                            <Route exact path="/freehold/*/laundry" component={Laundry} />
                            <Route exact path="/*/interior/basement_unfinished" component={BasementUnfin} />
                            <Route exact path="/*/interior/basement_unfinished/flooring" component={Article} />
                            <Route exact path="/*/interior/basement_finished" component={BasementFin} />
                            <Route exact path="/*/*/*/interior_finishes/flooring" component={Floor2} />                         
                            <Route exact path="/*/*/*/flooring" component={Floor} />
                            <Route exact path="/*/*/*/finished_flooring" component={Floor} />
                            <Route exact path="/*/interior/cold_room" component={Cold} />
                            <Route exact path="/*/*/hallway" component={Hallway}/>
                            { /* Interior Routes End */}

                            { /* Article Routes */}
                            { /*
                                I don't actually need these I just want them. They could be redone to only have a few,
                                but I find it easier to folow with mostly unique ones.
                            */ }
                            <Route exact path="/*/interior/*/electrical" component={Article} />
                            <Route exact path="/*/interior/*/cold_electrical" component={Article} />
                            <Route exact path="/*/interior/*/interior_doors" component={Article} />
                            <Route exact path="/*/condo_unit/*/interior_doors" component={Article} />
                            <Route exact path="/*/townhome_unit/*/interior_doors" component={Article} />
                            <Route exact path="/*/interior/living_room/doors_exterior" component={Article} />
                            <Route exact path="/*/interior/kitchen/doors_exterior" component={Article} />
                            <Route exact path="/*/condo_unit/living_room/doors_exterior" component={Article} />
                            <Route exact path="/*/interior/living_room/doors_interior" component={Article} />
                            <Route exact path="/*/interior/kitchen/doors_interior" component={Article} />
                            <Route exact path="/*/condo_unit/living_room/doors_interior" component={Article} />
                            <Route exact path="/*/*/exterior_columns" component={Article} />
                            <Route exact path="/*/*/garage/*" component={Article} />
                            <Route exact path="/*/interior/attic/*" component={Article} />
                            <Route exact path="/*/interior/cold_room/*" component={Article} />
                            <Route exact path="/*/*/*/climate_control" component={Article} />
                            <Route exact path="/*/*/*/ceiling" component={Article} />
                            <Route exact path="/*/*/*/wall" component={Article} />
                            <Route exact path="/*/*/*/unfinished_wall" component={Article} />
                            <Route exact path="/*/*/*/finished_wall" component={Article} />
                            <Route exact path="/*/*/*/interior_windows" component={Article} />
                            <Route exact path="/*/*/*/windows" component={Article} />
                            <Route exact path="/*/*/*/cabinets" component={Article} />
                            <Route exact path="/*/*/*/countertops" component={Article} />
                            <Route exact path="/*/*/*/bathtub" component={Article} />
                            <Route exact path="/*/*/*/climate_control" component={Article} />
                            <Route exact path="/*/*/*/stairs" component={Article} />
                            <Route exact path="/*/*/*/fireplace" component={Article} />
                            <Route exact path="/*/*/*/sinks" component={Article} />
                            <Route exact path="/*/*/*/sliding_door" component={Article} />
                            <Route exact path="/*/*/*/plumbing" component={Article} />
                            <Route exact path="/*/*/*/unfinished_plumbing" component={Article} />
                            <Route exact path="/*/*/*/walls" component={Article} />
                            <Route exact path="/*/*/*/flooring/*" component={Article} />
                            <Route exact path="/*/*/*/unfinished_floor" component={Article} />
                            <Route exact path="/*/*/*/trim" component={Article} />
                            <Route exact path="/*/*/hallway/*" component={Article} />
                            <Route exact path="/*/*/*/article" component={Article} />
                            <Route exact path="/*/*/*/*/article" component={Article} />
                            <Route exact path="/*/*/*/*/general" component={Article} />

                            <Route exact path="/*/exterior/roof/*" component={Article} />
                            <Route exact path="/*/*/electrical" component={Article} />
                            <Route exact path="/*/*/exterior_cladding/*" component={Article} />
                            <Route exact path="/*/exterior/exterior_paint" component={Article} />
                            <Route exact path="/*/exterior/exterior_wood" component={Article} />
                            <Route exact path="/*/exterior/water_penetration" component={Article} />
                            <Route exact path="/*/*/foundation" component={Article} />
                            <Route exact path="/*/exterior/landing" component={Article} />
                            <Route exact path="/*/exterior/exterior_windows" component={Article} />
                            <Route exact path="/*/exterior/filtration" component={Article} />
                            <Route exact path="/*/exterior/fireplace" component={Article} />
                            <Route exact path="/*/exterior/exterior_doors" component={Article} />
                            <Route exact path="/*/interior/kitchen/exterior_doors" component={Article} />
                            <Route exact path="/*/exterior/exterior_doors_man" component={Article} />
                            <Route exact path="/*/*/landscape_deck/*" component={Article} />
                            <Route exact path="/*/exterior/attic/*" component={Article} />
                            { /* Article Routes End */}

                            { /* Exterior Routes */}
                            <Route exact path="/freehold/exterior" component={ExteriorContainer} />
                            <Route exact path="/*/exterior/roof" component={Roof} />
                            <Route exact path="/*/*/exterior_cladding" component={Siding} />
                            <Route exact path="/*/exterior/landscape_deck" component={Landscape} />
                            <Route exact path="/*/*/garage" component={Garage} />
                            { /* Exterior Routes End */}

                            { /* Condo Routes */}
                            <Route exact path="/condo/condo_unit" component={CondoUnit} />
                            <Route exact path="/condo/townhome_unit" component={CondoUnit} />
                            <Route exact path="/condo/common_element" component={CommonArea} />
                            {//<Route exact path="/condo/townhome" component={CondoTownhome}/>
                            }
                            <Route exact path="/condo/common_element/building_exterior" component={BuildingExterior} />
                            <Route exact path="/condo/common_element/building_exterior/exterior_closure" component={Exterior} />
                            <Route exact path="/condo/common_element/building_exterior/exterior/exterior_cladding" component={Siding} />
                            <Route exact path="/condo/common_element/corridors" component={Corridors} />
                            <Route exact path="/condo/common_element/corridors/interior_finishes" component={InteriorFinishes} />
                            <Route exact path="/condo/common_element/parking_garage" component={ParkingGarage} />
                            <Route exact path="/condo/common_element/parking_garage/garage_exterior_door" component={ExteriorDoors} />
                            <Route exact path="/condo/common_element/garbage_recycling" component={GarbageRecycling} />
                            <Route exact path="/condo/common_element/garbage_recycling/interior_finishes" component={InteriorFinishesGarbage} />
                            <Route exact path="/condo/common_element/ground_lobby" component={GroundLobby} />
                            <Route exact path="/condo/common_element/ground_lobby/interior_finishes" component={InteriorFinishes} />
                            <Route exact path="/condo/common_element/pool_area" component={PoolArea} />
                            <Route exact path="/condo/common_element/pool_area/interior_finishes" component={InteriorFinishesPool} />
                            <Route exact path="/condo/common_element/mechanical_room" component={UtilitiesRoom} />
                            <Route exact path="/condo/common_element/amenity_room" component={Amenity} />
                            <Route exact path="/condo/common_element/amenity_room/interior_finishes" component={InteriorFinishesAmenity} />
                            <Route exact path="/condo/common_element/*" component={Article} />

                            <Route exact path="/condo/condo_unit/entry" component={CondoEntry} />
                            <Route exact path="/condo/condo_unit/bathroom" component={CondoBathroom} />
                            <Route exact path="/condo/condo_unit/kitchen" component={CondoKitchen} />
                            <Route exact path="/condo/condo_unit/bedroom" component={CondoBedroom} />
                            <Route exact path="/condo/condo_unit/laundry" component={CondoLaundry} />
                            <Route exact path="/condo/condo_unit/living_room" component={CondoLivingRoom} />
                            <Route exact path="/condo/townhome_unit/entry" component={CondoEntry} />
                            <Route exact path="/condo/townhome_unit/bathroom" component={CondoBathroom} />
                            <Route exact path="/condo/townhome_unit/kitchen" component={CondoKitchen} />
                            <Route exact path="/condo/townhome_unit/bedroom" component={CondoBedroom} />
                            <Route exact path="/condo/townhome_unit/laundry" component={CondoLaundry} />
                            <Route exact path="/condo/townhome_unit/living_room" component={CondoLivingRoom} />

                            <Route exact path="/condo/townhome" component={CondoExterior} />                            
                            <Route exact path="/condo/townhome/*/*" component={Article} />
                            <Redirect from="/condo/townhome/*" to="/condo/townhome" />                            

                            {  /* Condo Routes End */}

                            <Route exact path="/search" component={SearchContainer} />
                            <Route exact path="/search_ce" component={SearchContainer} />
                            <Route exact path="/search/:query" component={SearchContainer} />
                            <Route exact path="/search_ce/:query" component={SearchContainer} />
                            <Route path="*" component={NotFound} />
                        </Switch>
                    </div>
                </div>
            </Router>
        )
    }
}
