/* eslint import/no-webpack-loader-syntax: off */
/* eslint-disable  react/jsx-no-target-blank */

import React from 'react';
import WithMobile from './WithMobile';
import { Redirect } from 'react-router';

//import Start from '../images/Start.svg';
import Start from '../images/Start.min.svg';

import MobHouseImage from '../images/mobile_svgs/freehold-outside.svg';
import MobCondoImage from '../images/mobile_svgs/condo.svg';

import * as functions from './functions';

const Colors = [['freehold', false], ['condo', false]];
const title = "What kind of home do you live in?"
const navItems = [
    {
        path: "/freehold",
        title: "Freehold Home",
        class: "freehold",
        highlight_mode: 2
    },
    {
        path: "/condo",
        title: "Condominium",
        class: "condo",
        highlight_mode: 2
    }
];

const paragraph = <div className="explain">
                    <p><i>Freehold:</i> When a freehold home is purchased, the purchaser agrees to buy both a parcel of land and a residential dwelling unit to be constructed on that parcel of land. Various types of dwellings fit this category, including a single family detached home, a semi-detached home, a unit in a row house or a unit in a duplex. In the scenario you are the owner of the land and the home is contracted out to be built, please view information on our brochure <a href="https://tarion.com/media/warranty-coverage-new-homes-ontario-freehold-and-contract-homes-edition">Warranty Coverage for Freehold and Contract Homes</a> for more information on eligibility of warranty coverage.</p>
                    <p><i>Condominium:</i> When a condominium unit is purchased, the purchaser agrees to buy a residential dwelling unit within a condominium project containing multiple units and shared areas and/or amenities, referred to as common elements. Various types of dwellings fit this category, including a unit in a high-rise, mid-rise, or low-rise building, a unit in a row house, or a fully detached home.</p>
                </div>

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: "",
            isDesktop: false
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.matchMedia('(min-width: 768px)').matches }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = false;
        let SVG = this.state.isDesktop ?
            <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                <Start />
            </div>
            :
            <div className="tarion-cpg__mobile-container">
                <div onClick={(e) => this.onMobileClick('freehold')} className="tarion-cpg__svg-container pointer">
                    <MobHouseImage />
                    <p>Freehold Home</p>
                </div>
                <div onClick={(e) => this.onMobileClick('/condo')} className="tarion-cpg__svg-container pointer">
                    <MobCondoImage />
                    <p>Condominium</p>
                </div>
            </div>;
        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component = 
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile Paragraph={paragraph} showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors}/>
        )
    }
}

export default Container;