import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ModeLink from './ModeLink'
import * as functions from './functions';

class Navigation extends React.Component
{
    constructor(props)
    {
        super(props);
        this.Colors = this.props.hoverItems;
    }

    render()
    {

        const navListItems = this.props.items.map((item, i) => (
            (!item.mode_selected ?
                <li onMouseMove={((e) => functions.default.onMove(e, this.Colors, item.highlight_mode))} key={i}>
                    <Link className={(item.class ? item.class : "") + (item.mobile_show ? " mobile_show" : "") + (item.mobile_hide ? " mobile_hide" : "")} to={item.path}>{item.title}</Link>
                </li>
                :
                <ModeLink key={i} item={item} Colors={this.Colors} index={i} />)
        ));

        //if (this.props.showArticleLink)
       // {
            //let pathName = (this.props.items[0].path.split("/"))[0];
            //let parsedName = pathName + "/article";
            //let capName = pathName.replace("_", " ");
            //navListItems.push(<li key="-2">
             //   <Link to={parsedName}>{capName} Articles</Link>
            //</li>);
        //}

        return (
            <nav  className="tarion-cpg__nav">
                <ul>
                    {navListItems}
                </ul>
            </nav>
        )
    }
}

Navigation.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired
        })
    )
}

export default Navigation