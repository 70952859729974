/*eslint no-lone-blocks: 0 */
import React, { Component } from 'react'
import Slider from 'react-slick';
import YouTube from 'react-youtube';
import * as functions from '../functions';
import { SlideDown } from 'react-slidedown';

let open = true;

{/* eslint-disable  react/jsx-no-target-blank */ }
class Article_Freehold extends Component
{
    constructor(props)
    {
        super(props);
        this.article_data = props.article_data;
        this.article_bottom = props.article_bottom;
        this.info_bottom = props.info_bottom;
        this.opts = props.opts;
        this.img_srcs = props.img_srcs;
        this.settings = props.settings;
        this.related_articles = props.related_articles;
        this.showDisc = props.showDisc;
    }

    render()
    {
        return (<div className="article_data">
            <div id="article_info" className={`article_info
                                    ${this.article_bottom > this.info_bottom ? '' : 'height_100'}
                                `}
            >
                <h3>{this.article_data.title && functions.default.stripTags(this.article_data.title)}</h3>
                <p>
                    <span>Article Number</span>
                    {this.article_data.article_number}
                </p>
                <p>
                    <span>Condition</span>
                    {this.article_data.condition && functions.default.stripTags(this.article_data.condition)}
                </p>
                <p>
                    <span>Performance</span>
                    {this.article_data.performance && functions.default.stripTags(this.article_data.performance)}
                </p>
                <p>
                    <span>Warranty</span>
                    {this.article_data.warranty && functions.default.stripTags(this.article_data.warranty)}
                </p>
                <p>
                    <span>Action</span>
                    {this.article_data.action && functions.default.stripTags(this.article_data.action)}
                </p>
                <p id="last_p_article_info">
                    <span>Remarks</span>
                    {this.article_data.remarks && functions.default.stripTags(this.article_data.remarks)}
                </p>
            </div>
            <div className="article_pics">
            { this.showDisc ?
                <div className="disclaimer">
                    <div>
                        <p>
                            Refer to the Disclosure Package that was provided with your Agreement of Purchase and Sale or the registered Declaration and Description to find the unit/common element boundaries. 
                            You may also speak to your condominium board or condominium manager to find where a potential deficiency falls under. 
                            <span className="more_link" onClick={() => {open = !open; this.forceUpdate(); }}>&lt;MORE&gt;</span>
                        </p>
                        <SlideDown closed={open}>
                            <b>Common Elements refers to all the property in a residential condominium project except the units. It includes areas that are shared by residents e.g. lobbies, recreational facilities such as pools and gyms, exclusive use areas like balconies, parking garages and shared systems such as heating and electrical systems. These spaces/components are owned by the condominium corporation. To find more information about warranty coverage for common elements, you may find it&nbsp;</b>
                            <a target="_blank" rel="noopener" href="https://tarion.com/common-elements-coverage">here.</a>
                        </SlideDown>
                    </div>
                </div> : "" }
                {this.img_srcs.src0 ?
                    this.img_srcs.src1 ?
                        <div className="images">
                            <Slider {...this.settings}>
                                {this.img_srcs.src0}
                                {this.img_srcs.src1}
                                {this.img_srcs.src2 ? this.img_srcs.src2 : ""}
                                {this.img_srcs.src3 ? this.img_srcs.src3 : ""}
                                {this.img_srcs.src4 ? this.img_srcs.src4 : ""}
                            </Slider>
                        </div>
                        :
                        <div className="images">
                            {this.img_srcs.src0}
                        </div>
                    :
                    ""
                }
                <div className="related">
                    <p className="relatedHeader">RELATED ARTICLES</p>
                    {this.related_articles}
                </div>
                {this.article_data.video_id ?
                    <div className="videos">
                        <YouTube videoId={this.article_data.video_id} opts={this.opts} />
                    </div>
                    :
                    ""
                }
            </div>
        </div>
        )
    }
}

export default Article_Freehold