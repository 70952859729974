/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import WithMobile from '../WithMobile';
import BImage from '../../images/Interior_rooms/Basement-Unfinished.svg';

import MobElectrical from '../../images/mobile_svgs/electrical.svg';
import MobClimateControl from '../../images/mobile_svgs/air-filtration.svg';
import MobFinFloor from '../../images/mobile_svgs/unfinished-floors.svg';
import MobFinWall from '../../images/mobile_svgs/unfinished-walls.svg';
import MobWindows from '../../images/mobile_svgs/window.svg';
import MobStairs from '../../images/mobile_svgs/stairs.svg';
import MobPlumbing from '../../images/mobile_svgs/sink.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const title = "Select an area"
const navItems = [
    {
        path: "basement_unfinished/cold_electrical",
        title: "Electrical",
        class: "cold_electrical",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/unfinished_floor",
        title: "Unfinished Floor",
        class: "unfinished_floor",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/windows",
        title: "Windows",
        class: "windows",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/unfinished_wall",
        title: "Unfinished Walls",
        class: "unfinished_wall",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/climate_control",
        title: "Interior Climate Control",
        class: "climate_control",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/unfinished_plumbing",
        title: "Plumbing",
        class: "unfinished_plumbing",
        highlight_mode: 2
    },
    {
        path: "basement_unfinished/stairs",
        title: "Stairs",
        class: "stairs",
        highlight_mode: 2
    }
]
const Colors = [
    ['climate_control', false], ['cold_electrical', false], ['unfinished_floor', false], ['unfinished_wall', false], ['unfinished_plumbing', false],
    ['stairs', false], ['windows', false]
];

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "basement_unfinished/" + item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <BImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('basement_unfinished/cold_electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('basement_unfinished/climate_control')} className="tarion-cpg__svg-container">
                    <MobClimateControl />
                    <p>Climate Control</p>
                </div>

                <div onClick={(e) => this.onMobileClick('basement_unfinished/plumbing')} className="tarion-cpg__svg-container">
                    <MobPlumbing />
                    <p>Plumbing</p>
                </div>
                <div onClick={(e) => this.onMobileClick('basement_unfinished/unfinished_floor')} className="tarion-cpg__svg-container">
                    <MobFinFloor />
                    <p>Unfinished Flooring</p>
                </div>
                <div onClick={(e) => this.onMobileClick('basement_unfinished/unfinished_wall')} className="tarion-cpg__svg-container">
                    <MobFinWall />
                    <p>Unfinished Walls</p>
                </div>
                <div onClick={(e) => this.onMobileClick('basement_unfinished/windows')} className="tarion-cpg__svg-container">
                    <MobWindows />
                    <p>Windows</p>
                </div>
                
                <div onClick={(e) => this.onMobileClick('basement_unfinished/stairs')} className="tarion-cpg__svg-container">
                    <MobStairs />
                    <p>Stairs</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container