import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { v4 } from 'uuid'

class SearchForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      input: ""
    }
    this.fR = "";
    this.path = "";
  }

  render() {
    const { location } = this.props
    const inputId = v4()

    if(location.pathname !== "/" && location.pathname !== "/condo")
    {
        this.path = location.pathname;

        let Search = "Search ";
        if (this.path.includes('common_element') || this.path.split("/")[2] === 'townhome' || this.path.includes('search_ce'))
            Search += "CE Articles"
        else
            Search += "FH Articles";

        return (
        <form className="tarion-cpg__search-form" onSubmit={this.handleSubmit}>
            <label htmlFor={inputId}>Search</label>
            <input type="text"
            id={inputId}
            className="tarion-cpg__search-input"
            name="search"
            placeholder={Search}
            onChange={ e => this.setState({ input: e.target.value }) }
            ref={el => this.fR = el }
            />
            <button className="mobile_show submit" type="submit">Go</button>
        </form>
        )
    }
    else
    {
        return "";
    }
  } 

  handleSubmit = event => {
    event.preventDefault()

    const { history } = this.props;
    const { input } = this.state;
    const fR = this.fR.value;

    if(fR.length > 0)
    {
        if (this.path.includes('common_element') || this.path.split("/")[2] === 'townhome' || this.path.includes('search_ce'))
            history.push(`/search_ce/${input}`);
        else
            history.push(`/search/${input}`);

        this.fR.value = "";
    }
    else
    {
        this.fR.focus();
    }
  }
}

SearchForm.propTypes = {
  buttonFillColour: PropTypes.string
}

SearchForm.defaultProps = {
  buttonFillColour: "#000000"
}

export default withRouter(SearchForm)