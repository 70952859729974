/*eslint eqeqeq: 0*/

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import loading from '../images/loading.gif';
import axios from 'axios';
import BackButton from './BackButton';
import A2 from './articles/appendices/A2';
import ArticleFreehold from './articles//ArticleFreehold';
import ArticleCE from './articles/ArticleCE';

const extCat = ['Exterior Cladding', 'Exterior Columns', 'Garage', 'Exterior Doors', 'Exterior Doors Man', 'Exterior Paint'];
const intCat = ['Ceiling', 'Flooring', 'Interior Doors', 'Interior Finishing', 'Wall'];
const roomCat = ['Amenity Room', 'Building Exterior', 'Garabge Recycling', 'Ground Lobby', 'Mechanical Room', 'Pool Area', 'Parking Garage'];


class SearchContainer extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            selectedArticle: null,
            categories: [],
            articles: [],
            filtered_articles: [],
            showArticle: false,
            info_bottom: 0,
            article_bottom: 0,
            articleID: 0,
            loadedArticle2: false
        }
        this.articles = [];
        this.articleData = [];
        this.SizingInterval = null;
        this.loading = true;
        this.ce = false;
        this.query = "";
        this.showArticle = false;        
    }

    componentDidMount()
    {
        this.setState({ 'selectedArticle': null });
        let articles = [];
        if(this.props.location.pathname.includes("search_ce"))
        {
            this.ce = true;
            axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/ce?_format=json&time=1041', method: 'get', responseType: 'json' })
                .then((event) =>
                {
                    if(articles.length)
                    {
                        articles = articles.concat(event.data.sort(
                            function (a, b)
                            {
                                var left = a.number.split('.');
                                var right = b.number.split('.');
                                if (Number(left[0]) < Number(right[0]))
                                    return -1;
                                if (Number(left[0]) > Number(right[0]))
                                    return 1;
                                if (Number(left[1]) < Number(right[1]))
                                    return -1;
                                if (Number(left[1]) > Number(right[1]))
                                    return 1;
                                return 0;
                            }
                        ));
                        this.setState({'articles': articles
                            , 'loadedArticles': true
                        });

                        const { query } = this.props.match.params;
                        const filteredArticles = this.filterList(query);
                        this.setState({ filtered_articles: filteredArticles });

                        if (filteredArticles.length > 0) {
                            this.selectArticle(filteredArticles[0].id, 0);
                        }
                    }
                    // @todo: this appears to not do anything, once this logic
                    // is understood (including the other axios calls and if
                    // statements), revise and remove this if unnecessary.
                    else
                    {
                        articles = articles.concat(event.data.sort(
                            function (a, b)
                            {
                                var left = a.number.split('.');
                                var right = b.number.split('.');
                                if (Number(left[0]) < Number(right[0]))
                                    return -1;
                                if (Number(left[0]) > Number(right[0]))
                                    return 1;
                                if (Number(left[1]) < Number(right[1]))
                                    return -1;
                                if (Number(left[1]) > Number(right[1]))
                                    return 1;
                                return 0;
                            }
                        ));
                    }
                })
                .catch((event) => { console.log(event); this.setState({ 'articles': [], 'loadedArticles': true }); });
        }
        axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/freehold?_format=json&time=1055', method: 'get', responseType: 'json' })
            .then((event) =>
            {
                if(this.ce)
                {
                    let filtered = [];
                    for(let item of event.data)
                        if(this.validFHArticle(item))
                            filtered.push(item);
                    articles = articles.concat(filtered.sort(
                        function (a, b)
                        {
                            var left = a.number.split('.');
                            var right = b.number.split('.');
                            if (Number(left[0]) < Number(right[0]))
                                return -1;
                            if (Number(left[0]) > Number(right[0]))
                                return 1;
                            if (Number(left[1]) < Number(right[1]))
                                return -1;
                            if (Number(left[1]) > Number(right[1]))
                                return 1;
                            return 0;
                        }
                    ));
                }
                else
                {
                    articles = articles.concat(event.data.sort(
                        function (a, b)
                        {
                            var left = a.number.split('.');
                            var right = b.number.split('.');
                            if (Number(left[0]) < Number(right[0]))
                                return -1;
                            if (Number(left[0]) > Number(right[0]))
                                return 1;
                            if (Number(left[1]) < Number(right[1]))
                                return -1;
                            if (Number(left[1]) > Number(right[1]))
                                return 1;
                            return 0;
                        }
                    ));
                }
                this.setState({
                    'articles': articles
                    , 'loadedArticles': true
                });

                const { query } = this.props.match.params;
                const filteredArticles = this.filterList(query);
                this.setState({ filtered_articles: filteredArticles });
                
                if (filteredArticles.length > 0) {
                    this.selectArticle(filteredArticles[0].id, 0);
                }
            })
            .catch((event) => { console.log(event); this.setState({ 'articles': [], 'loadedArticles': true }); });


        this.SizingInterval = setInterval(() =>
        {
            if (document.querySelector("#article_info"))
            {
                this.setState({
                    'article_bottom': document.querySelector("#last_p_article_info").getBoundingClientRect().bottom,
                    'info_bottom': document.querySelector("#article_info").getBoundingClientRect().bottom
                });
                clearInterval(this.SizingInterval);
            }
        }, 1000);
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.match.params != prevProps.match.params)
        {
            const { query } = this.props.match.params;
            this.filterList(query);
        }
    }

    splitAndMatch(strings, array)
    {
        let arrStrings = strings.split(',');

        for(let item of arrStrings)
        {
            if(array.includes(item.trim()))
                return true;
        }
        return false;
    }

    validFHArticle(item)
    {
        if(item.show && item.show.includes("common_element"))
            return true;
        
        if(item.inout.includes("Common Element"))
            return true;

        let match = !item.inout || item.inout.includes("Exterior");
        if(item.room)
        {
            if(match && this.splitAndMatch(item.room, extCat))
            {
                if(item.room.includes("Garage"))
                {
                    if(item.category.includes("Garage"))
                        return true;
                }
                else if (item.room.includes("Exterior Doors"))
                {
                    if (item.category.includes("Exterior Doors"))
                        return true;
                }  
                else
                    return true;
            }

            match = !item.inout || item.inout.includes("Interior");
            if (match && this.splitAndMatch(item.room, ['Bedroom']))
            {
                if (this.splitAndMatch(item.category, intCat))
                    return true;
            }
            else if (this.splitAndMatch(item.room, ['Garage']))
            {
                if (item.category.includes("Garage"))
                    return true;
            }
            else if (item.room.includes("Exterior Doors"))
            {
                if (item.category.includes("Exterior Doors"))
                    return true;
            }
            
            if(this.splitAndMatch(item.room, roomCat))
                return true;
        }

        if(!item.inout && !item.room)
            return true;

        return false;
    }

    filterList(query)
    {
        const query_ = decodeURIComponent(query);
        let filteredList = [];
        let number = 0;
        if (Number(query_) && query_.includes('.'))
            number = query_;

        let querySplit = query_.split(" ");

        for (let item of this.state.articles)
        {
            if(!item.title || !item.number) 
                continue;
            item.title = item.title.replace(/&#039;/g, "'");
            item.title = item.title.replace(/&quot;/g, "\"");
          
            let found = 0;
            for(var i = 0,len = querySplit.length; i<len;i++)
            {
                if (item.title.toUpperCase().includes(querySplit[i].toUpperCase()))
                {
                    filteredList.push(item);
                    break;
                }
                else if(number && item.number == number)
                {
                    filteredList.unshift(item);
                    break;
                }
                else if (!number && item.remarks && item.remarks.toUpperCase().includes(" " + querySplit[i].toUpperCase() + " "))
                {
                    if(i < len - 1)
                        found++;
                    else
                    {
                        filteredList.push(item);
                        break;
                    }
                }
                else if (number && item.remarks && item.performance.includes(" " + number + " "))
                {
                    if (i < len - 1)
                        found++;
                    else
                    {
                        filteredList.push(item);
                        break;
                    }
                }
                else if (!number && item.performance && item.performance.toUpperCase().includes(" " + querySplit[i].toUpperCase() + " "))
                {
                    if (i < len - 1)
                        found++;
                    else
                    {
                        filteredList.push(item);
                        break;
                    }
                }
                else if (number && item.remarks && item.remarks.includes(" " + number + " "))
                {
                    if (i < len - 1)
                        found++;
                    else
                    {
                        filteredList.push(item);
                        break;
                    }
                }
                else if(found)
                {
                    let word = querySplit[i].substr(0,3);
                    if (
                        (item.remarks && item.remarks.toUpperCase().includes(" " + word.toUpperCase())) ||
                        (item.performance && item.performance.toUpperCase().includes(" " + word.toUpperCase())) 
                    )
                        filteredList.push(item);
                }
            }
        }

        return filteredList;
    }

    selectArticle(val, index, appendix = null)
    {
        if (val)
        {
            if (this.state.showSideBar)
                this.setState({ 'showSideBar': false, "articleID": index });
            if (this.articleData[val])
            {
                this.setState({ 'selectedArticle': this.articleData[val], "articleID": index });
            }
            else
            {
                if (this.state.loadedArticle2)
                    this.setState({ loadedArticle2: false });
                axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/article?nid=' + val + '&_format=json', method: 'get', responseType: 'json' })
                    .then((event) => { this.setState({ 'loadedArticle2': true,'showArticle': true, 'selectedArticle': event.data[0], "articleID": index, articleData: { [val]: event.data[0], ...this.articleData } }) })
                    .catch((event) => { this.setState({ 'selectedArticle': null, "articleID": index }); });
            }
        }
        else if (appendix)
        {
            this.setState({ 'showArticle': true, 'selectedArticle': appendix, "articleID": index });
        }
    }

    getRelatedArticles()
    {
        let data = [];
        let filteredData = [];
        for (let item of this.state.selectedArticle.related.split(","))
            data.push(Number(item));

        for (let item2 of this.state.articles)
        {
            if (data.includes(Number(item2.id)))
                filteredData.push(<p key={item2.id} onClick={() => this.selectArticle(item2.id, 0)} className="link">{item2.title}</p>);
        }

        if (this.state.selectedArticle.remarks.includes("A2") || this.state.selectedArticle.performance.includes("A2"))
            filteredData.push(<p key="A2" onClick={() => this.selectArticle(null,0, "A2")} className="link">Appendix A2</p>);
        if (this.state.selectedArticle.remarks.includes("A3") || this.state.selectedArticle.performance.includes("A3"))
            filteredData.push(<p key="A3" onClick={() => this.selectArticle(null,0, "A3")} className="link">Appendix A3</p>);
        if (this.state.selectedArticle.remarks.includes("A4") || this.state.selectedArticle.performance.includes("A4"))
            filteredData.push(<p key="A4" onClick={() => this.selectArticle(null,0, "A4")} className="link">Appendix A4</p>);
        if (this.state.selectedArticle.remarks.includes("A5") || this.state.selectedArticle.performance.includes("A5"))
            filteredData.push(<p key="A5" onClick={() => this.selectArticle(null,0, "A5")} className="link">Appendix A5</p>);
        if (this.state.selectedArticle.remarks.includes(" A6") || this.state.selectedArticle.performance.includes("A6"))
            filteredData.push(<p key="A6" onClick={() => this.selectArticle(null,0, "A6")} className="link">Appendix A6</p>);

        return filteredData;
    }

    getPreviousArticle()
    {
        if (this.state.articleID > 0)
            this.selectArticle(this.state.filtered_articles[this.state.articleID - 1].id, this.state.articleID - 1);
        else
            this.selectArticle(this.state.filtered_articles[this.state.filtered_articles.length - 1].id, this.state.filtered_articles.length - 1);
    }

    getNextArticle()
    {
        if (this.state.articleID < this.state.filtered_articles.length - 1)
            this.selectArticle(this.state.filtered_articles[this.state.articleID + 1].id, this.state.articleID + 1);
        else
            this.selectArticle(this.state.filtered_articles[0].id, 0);
    }

    showSidebar(evt)
    {
        this.setState({ 'showSideBar': !this.state.showSideBar });
        evt.stopPropagation();
    }

    hideSideBar()
    {
        if (this.state.showSideBar)
            this.setState({ 'showSideBar': false });
    }

    render()
    {
        var settings = {
            dots: true
        }
        const { query } = this.props.match.params;
        const opts = {
            height: '190',
            width: '300'
        };

        let beep = null;
        if (this.state.filtered_articles)
        {
            let articlesList = this.state.filtered_articles;
            if (articlesList.length && (!this.showArticle ||  query != this.query))
            {
                if(!isNaN(query) && query.indexOf(".") > 0)
                    for(var i = 0, len = articlesList.length; i < len; i++)
                    {
                        if (query == articlesList[i].number)
                        {
                            this.selectArticle(articlesList[i].id, i, null, query);
                            break;
                        }
                    }
                else
                    this.selectArticle(articlesList[0].id, 0, null, query);

                this.query = query;
                this.showArticle = true;
            }    
            beep = articlesList.map((item, i) => (
                <p key={item.id} 
                    onClick={() => this.selectArticle(item.id, i)} 
                    className={`link ${this.state.selectedArticle && this.state.selectedArticle.nid && this.state.selectedArticle.nid == item.id ? 'active' : ''}`}
                >
                    <span>{item.type == "CE Construction Performance Guidelines" ? "CE " : "FH "}</span>
                    {item.number}: {item.title}
                </p>
            ));            
            if (beep.length === 0)
                beep = <p>Your search yielded no results.</p>
            else
                this.loading = false;
        }
        else
            beep = <img src={loading} alt="loading" className="img" />;

        let img_srcs = {
            src0: null,
            src1: null,
            src2: null,
            src3: null,
            src4: null
        }
        if (this.state.selectedArticle && this.state.selectedArticle.images)
        {
            let arrImg = this.state.selectedArticle.images.split(",");
            for (i = 0, len = arrImg.length; i < len; i++)
                img_srcs['src' + i] = <img src={process.env.REACT_APP_API_URI + arrImg[i].trim()} alt={'Image ' + (i + 1)} />;
        }

        let showDisc = this.props.location.pathname.includes("search_ce");

        return (
            <div className="articleContainer search" onClick={() => this.hideSideBar()}>                
                <div className="search article_back_button">
                    <BackButton />
                    <h2>Search results for <em>{query ? decodeURIComponent(query) : "none"}</em></h2>
                </div>                
                <div className="mobile_article_sidebar search" onClick={(evt) => this.showSidebar(evt)}>
                    <svg className="menu_breadcrumb" height="30" width="30" viewBox="0 0 30 30">
                        <line x1="0" y1="3" x2="30" y2="3" />
                        <line x1="0" y1="15" x2="30" y2="15" />
                        <line x1="0" y1="27" x2="30" y2="27" />
                    </svg>
                </div>
                <div className={`article_sidebar ${this.loading ? 'center' : ''} ${this.state.showSideBar ? 'mobile_visible' : ''} `}>
                    {beep}
                </div>
                { typeof this.state.selectedArticle !== "string" ?
                    this.state.selectedArticle && (this.state.selectedArticle.article_number || this.state.selectedArticle.ce_article_number) && this.state.loadedArticle2 ?
                        this.state.selectedArticle.type === "Construction Performance Guidelines" ?
                            <ArticleFreehold article_data={this.state.selectedArticle}
                                article_bottom={this.state.article_bottom}
                                info_bottom={this.state.info_bottom}
                                img_srcs={img_srcs}
                                related_articles={this.getRelatedArticles()}
                                settings={settings}
                                opts={opts}
                                showDisc={showDisc}  
                            />
                            : // this.state.selectedArticle.type === "Construction Performance Guidelines" ? 
                            <ArticleCE article_data={this.state.selectedArticle}
                                article_bottom={this.state.article_bottom}
                                info_bottom={this.state.info_bottom}
                                img_srcs={img_srcs}
                                related_articles={this.getRelatedArticles()}
                                settings={settings}
                                opts={opts}
                            />
                        : //this.state.selectedArticle && this.state.selectedArticle.article_number ?
                        <div className="article_loading">
                            <img src={loading} alt="loading" className="loading_img img" />
                        </div>
                    : //typeof this.state.selectedArticle !== "string" ?
                    <A2 appendix={this.state.selectedArticle} />
                }
                <div className="prev_next">
                    <div>
                        <span onClick={() => this.getPreviousArticle()}>&lsaquo;</span>
                        <p onClick={() => this.getPreviousArticle()}>Previous</p>
                    </div>
                    <div>
                        <p onClick={() => this.getNextArticle()}>Next</p>
                        <span onClick={() => this.getNextArticle()}>&rsaquo;</span>
                    </div>
                </div>
            </div>
        )
    }

    handleReset = () => this.props.history.push('/search')
}

export default withRouter(SearchContainer)