/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../WithMobile'

//import Inside from '../../images/Freehold-Interior.svg';
import Inside from '../../images/Freehold-Interior.min.svg';

import MobBedroom from '../../images/mobile_svgs/Bedroom.svg';
import MobBathroom from '../../images/mobile_svgs/Bathroom.svg';
import MobKitchen from '../../images/mobile_svgs/Kitchen.svg';
import MobHallway from '../../images/mobile_svgs/Hallway.svg';
import MobLivingRoom from '../../images/mobile_svgs/Living-Room.svg';
import MobGarage from '../../images/mobile_svgs/Garage.svg';
import MobAttic from '../../images/mobile_svgs/Attic.svg';
import MobColdRoom from '../../images/mobile_svgs/Cold-Room.svg';
import MobLaundryRoom from '../../images/mobile_svgs/Laundry-Room.svg';
import MobFinBasement from '../../images/mobile_svgs/Finished-Basement.svg';
import MobUnfinBasement from '../../images/mobile_svgs/Unfinished-Basement.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['bedroom', false], ['bathroom', false], ['living_room', false], ['kitchen', false],
    ['garage', false], ['cold_room', false], ['laundry', false], ['attic', false],
    ['basement_finished', false], ['basement_unfinished', false], ['basement', false], ['hallway', false]
];
const title = "Select a room"
const navItems = [
    {
        path: "interior/bedroom",
        title: "Bedroom",
        class: "bedroom",
        highlight_mode: 2
    },
    {
        path: "interior/bathroom",
        title: "Bathroom",
        class: "bathroom",
        highlight_mode: 2
    },
    {
        path: "interior/living_room",
        title: "Living Room",
        class: "living_room",
        highlight_mode: 2
    },
    {
        path: "interior/hallway",
        title: "Hallway",
        class: "hallway",
        highlight_mode: 2
    },
    {
        path: "interior/kitchen",
        title: "Kitchen",
        class: "kitchen",
        highlight_mode: 2
    },
    {
        path: "interior/garage",
        title: "Garage",
        class: "garage",
        highlight_mode: 2
    },
    {
        path: "interior/cold_room",
        title: "Cold Room and Crawl Space",
        class: "cold_room",
        highlight_mode: 2
    },
    {
        path: "interior/laundry",
        title: "Laundry Room",
        class: "laundry",
        highlight_mode: 2
    },
    {
        path: "interior/attic",
        title: "Attic",
        class: "attic",
        highlight_mode: 2
    },
    {
        path: "interior/basement_finished",
        title: "Finished Basement",
        id: "basement_unfinished",
        mobile_show: true,
        class: "basement_unfinished basement_finished"
    },
    {
        path: "interior/basement_unfinished",
        title: "Unfinished Basement",
        id: "basement_unfinished",
        mobile_show: true,
        class: "basement_unfinished basement_finished"
    },
    {
        path: "interior/basement",
        title: "Basement",
        id: "basement_unfinished",
        mode_selected: 'unfinished',
        class: "basement_unfinished basement_finished",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }        
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes,Colors))
            this.setState({ redirect: true, redirectTo: "interior/" + classes.split(" ")[0] });
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <Inside />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('interior/bedroom')} className="tarion-cpg__svg-container">
                    <MobBedroom />
                    <p>Bedroom</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/bathroom')} className="tarion-cpg__svg-container">
                    <MobBathroom />
                    <p>Bathroom</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/kitchen')} className="tarion-cpg__svg-container">
                    <MobKitchen />
                    <p>Kitchen</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/living_room')} className="tarion-cpg__svg-container">
                    <MobLivingRoom />
                    <p>Living Room</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/garage')} className="tarion-cpg__svg-container">
                    <MobGarage />
                    <p>Garage</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/attic')} className="tarion-cpg__svg-container">
                    <MobAttic />
                    <p>Attic</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/laundry')} className="tarion-cpg__svg-container">
                    <MobLaundryRoom />
                    <p>Laundry Room</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/basement_finished')} className="tarion-cpg__svg-container">
                    <MobFinBasement />
                    <p>Finished Basement</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/basement_unfinished')} className="tarion-cpg__svg-container">
                    <MobUnfinBasement />
                    <p>Unfinished Basement</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/cold_room')} className="tarion-cpg__svg-container">
                    <MobColdRoom />
                    <p>Cold Room</p>
                </div>
                <div onClick={(e) => this.onMobileClick('interior/hallway')} className="tarion-cpg__svg-container">
                    <MobHallway />
                    <p>Hallway</p>
                </div>
            </div>;
        

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container;