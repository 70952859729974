import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

const routes = [
    {
        path: "/freehold",
        name: "freehold",
        title: "Freehold Home"
    },
    {
        name: "condo",
        path: "/condo",
        title: "Condominium"
    },
    {
        name: "article",
        path: "/article",
        title: "Article"
    },
    {
        name: "interior",
        path: "/interior",
        title: "Interior"
    },
    {
        name: "townhome",
        path: "/townhome",
        title: "Townhome"
    },
    {
        name: "exterior",
        path: "/exterior",
        title: "Exterior"
    },
    {
        name: "exterior_closure",
        path: "/exterior_closure",
        title: "Exterior Closure"
    },
    {
        name: "condo_unit",
        path: "/condo_unit",
        title: "Condominium Unit"
    },
    {
        name: "townhome_unit",
        path: "/townhome_unit",
        title: "Townhome Unit"
    },
    {
        name: "common_element",
        path: "/common_element",
        title: "Common Element"
    },

    //Interior Categories Start
    {
        name: "bedroom",
        path: "/bedroom",
        title: "Bedroom"
    },
    {
        name: "bathroom",
        path: "/bathroom",
        title: "Bathroom"
    },
    {
        name: "living_room",
        path: "/living_room",
        title: "Living Room"
    },
    {
        name: "kitchen",
        path: "/kitchen",
        title: "Kitchen"
    },
    {
        name: "garage",
        path: "/garage",
        title: "Garage"
    },
    {
        name: "cold_room",
        path: "/cold_room",
        title: "Cold Room and Crawl Space"
    },
    {
        name: "laundry",
        path: "/laundry",
        title: "Laundry Room"
    },
    {
        name: "hallway",
        path: "/hallway",
        title: "Hallway"
    },
    {
        name: "basement_finished",
        path: "/basement_finished",
        title: "Finished Basement"
    },
    {
        name: "basement_unfinished",
        path: "/basement_unfinished",
        title: "Unfinished Basement"
    },
    //Interior Area End

    //Main Exterior Categories
    {
        path: "/roof",
        name: "roof",
        title: "Roof"
    },
    {
        name: "electrical",
        path: "/electrical",
        title: "Electrical"
    },
    {
        name: "cold_electrical",
        path: "/cold_electrical",
        title: "Electrical"
    },
    {
        name: "exterior_cladding",
        path: "/exterior_cladding",
        title: "Exterior Cladding"
    },
    {
        name: "exterior_paint",
        path: "exterior_paint",
        title: "Exterior Paint"
    },
    {
        name: "exterior_wood",
        path: "/exterior_wood",
        title: "Exterior Wood"
    },
    {
        name: "water_penetration",
        path: "/water_penetration",
        title: "Water Penetration"
    },
    {
        name: "foundation",
        path: "/foundation",
        title: "Foundation"
    },
    {
        name: "landing",
        path: "/landing",
        title: "Landing or Steps"
    },
    {
        name: "exterior_windows",
        path: "/exterior_windows",
        title: "Windows"
    },
    {
        name: "filtration",
        path: "/filtration",
        title: "Filtration"
    },
    {
        name: "fireplace",
        path: "/fireplace",
        title: "Fireplace or Chimney"
    },
    {
        name: "exterior_doors",
        path: "/exterior_doors",
        title: "Exterior Doors"
    },
    {
        name: "exterior_doors_man",
        path: "/exterior_doors_man",
        title: "Garage & Man Doors"
    },
    {
        name: "landscape_deck",
        path: "/landscape_deck",
        title: "Landscape or Deck"
    },
    {
        name: "attic",
        path: "/attic",
        title: "Attic"
    },
    {
        name: "general",
        path: "/general",
        title: "General"
    },
    //Exterior Areas End *************************************

    //Interior Items ***********************************************
    {
        name: "climate_control",
        path: "/climate_control",
        title: "Interior Climate Control",
    },
    {
        name: "bathtub",
        path: "/bathtub",
        title: "Bathtub/Shower/Sink/Toilet"
    },
    {
        name: "finished_wall",
        path: "/finished_wall",
        title: "Finished Wall"
    },
    {
        name: "unfinished_wall",
        path: "/unfinished_wall",
        title: "Unfinished Wall"
    },
    {
        name: "cabinets",
        path: "/cabinets",
        title: "Cabinets"
    },
    {
        name: "ceiling",
        path: "/ceiling",
        title: "Ceiling"
    },
    {
        name: "chimney",
        path: "/chimney",
        title: "Chimney"
    },
    {
        name: "climate_control",
        path: "/climate_control",
        title: "Interior Climate Control"
    },
    {
        name: "countertops",
        path: "/countertops",
        title: "Countertops"
    },
    {
        name: "electrical",
        path: "/electrical",
        title: "Electrical"
    },
    {
        name: "flooring",
        path: "/flooring",
        title: "Flooring"
    },
    {
        name: "unfinished_floor",
        path: "/unfinished_floor",
        title: "Unfinished Floor"
    },
    {
        name: "cold_floor",
        path: "/cold_floor",
        title: "Unfinished Floor"
    },
    {
        name: "cold_wall",
        path: "/cold_wall",
        title: "Unfinished Wall"
    },
    {
        name: "finished_flooring",
        path: "/finished_flooring",
        title: "Finished Flooring"
    },
    {
        name: "interior_finish",
        path: "/interior_finish",
        title: "Interior Finishes"
    },
    {
        name: "interior_finishes",
        path: "/interior_finishes",
        title: "Interior Finishes"
    },
    {
        name: "plumbing",
        path: "/plumbing",
        title: "Plumbing"
    },
    {
        name: "unfinished_plumbing",
        path: "/unfinished_plumbing",
        title: "Plumbing"
    },
    {
        path: "/stairs",
        name: "stairs",
        title: "Stairs"
    },
    {
        name: "sink",
        path: "/sink",
        title: "Sinks"
    },
    {
        name: "sliding_door",
        path: "/sliding_door",
        title: "Sliding Door"
    },
    {
        name: "wall",
        path: "/wall",
        title: "Wall"
    },
    {
        name: "wall_framing",
        path: "/wall_framing",
        title: "Wall Framing"
    },
    {
        name: "interior_windows",
        path: "/interior_windows",
        title: "Windows"
    },

    //Floor
    {
        name: "tile_ceramic",
        path: "/tile_ceramic",
        title: "Tile or Ceramic"
    },
    {
        name: "vinyl",
        path: "/vinyl",
        title: "Vinyl"
    },
    {
        name: "carpet",
        path: "/carpet",
        title: "Carpet"
    },
    {
        name: "hardwood_parquet",
        path: "/hardwood_parquet",
        title: "Hardwood or Parquet"
    },
    {
        name: "laminate",
        path: "/laminate",
        title: "Laminate"
    },

    //Interior Finishes
    {
        name: "doors_interior",
        path: "/doors_interior",
        title: "Interior Doors"
    },
    {
        name: "doors_exterior",
        path: "/doors_exterior",
        title: "Exterior Doors"
    },
    {
        name: "door",
        path: "/door",
        title: "Interior Doors"
    },
    {
        name: "interior_doors",
        path: "/interior_doors",
        title: "Interior Doors"
    },
    {
        name: "doors",
        path: "/doors",
        title: "Doors"
    },
    {
        name: "garage_floor",
        path: "/garage_floor",
        title: "Garage Floor"
    },
    {
        name: "trim",
        path: "/trim",
        title: "Trim"
    },

    //Wall Framing
    {
        name: "windows_framing",
        path: "/windows_framing",
        title: "Windows"
    },

    {
        name: "windows",
        path: "/windows",
        title: "Windows"
    },
    {
        name: "ce_windows",
        path: "/ce_windows",
        title: "Windows"
    },
    //Interior Items End

    //Exterior Items Start
    {
        path: "/eavestroughs",
        name: "eavestroughs",
        title: "Eavestroughs"
    },
    {
        path: "/shingles",
        title: "Shingles",
        name: "shingles"
    },
    {
        path: "/other",
        name: "other",
        title: "Other"
    },
    {
        path: "/masonry",
        name: "masonry",
        title: "Masonry"
    },
    {
        path: "/aluminum_vinyl",
        name: "aluminum_vinyl",
        title: "Aluminum or Vinyl"
    },
    {
        path: "/brick",
        name: "brick",
        title: "Brick"
    },
    {
        path: "/stucco",
        name: "stucco",
        title: "Stucco"
    },
    {
        path: "/wood",
        name: "wood",
        title: "Wood Siding"
    },
    {
        path: "/driveway",
        name: "driveway",
        title: "Driveway"
    },
    {
        path: "/decks",
        name: "decks",
        title: "Decks"
    },
    {
        path: "/landscape",
        name: "landscape",
        title: "Landscape"
    },
    {
        path: "/water_supply",
        name: "water_supply",
        title: "Water Supply"
    },
    //Exterior Items End

    {
        path: "/search",
        name: "search",
        title: "Search Freehold Articles"
    },
    {
        path: "/search_ce",
        name: "search_ce",
        title: "Search Common Element Articles"
    },

    {
        path: "/entry",
        name: "entry",
        title: "Entry"
    },
    {
        path: "/windows",
        name: "windows",
        title: "Windows"
    },
    {
        path: "/building_exterior",
        name: "building_exterior",
        title: "Building Exterior"
    },
    {
        path: "/structure",
        name: "structure",
        title: "Structure"
    },
    {
        path: "/fire_safety",
        name: "fire_safety",
        title: "Fire Safety"
    },
    {
        path: "/exterior_columns",
        title: "Exterior Columns",
        name: "exterior_columns"
    },
    {
        path: "/cold_room_door",
        title: "Cold Room Door",
        name: "cold_room_door"
    },

    {
        path: "/electrical_room",
        title: "Electrical Room",
        name: "electrical_room"
    },
    {
        path: "/balcony",
        title: "Balcony",
        name: "balcony"
    },
    {
        path: "/roof",
        title: "Roof",
        name: "roof"
    },
    {
        path: "/landscaping",
        title: "Landscaping",
        name: "landscaping"
    },
    {
        path: "/barrier_free",
        title: "Barrier Free",
        name: "barrier_free"
    },
    {
        path: "/generator_room",
        title: "Generator Room",
        name: "generator_room"
    },
    {
        path: "/garbage_closet",
        title: "Garbage Closet",
        name: "garbage_closet"
    },
    {
        path: "/interior_common_element",
        title: "Interior Common Element",
        name: "interior_common_element"
    },
    {
        path: "/building_envelope",
        title: "Exterior Building Envelope",
        name: "building_envelope"
    },
    {
        path: "/parking_garage",
        title: "Parking or Garage",
        name: "parking_garage"
    },
    {
        path: "/exit_ramp",
        title: "Exit Ramp",
        name: "exit_ramp"
    },
    {
        path: "/garbage_disposal",
        title: "Garbage Disposal",
        name: "garbage_disposal"
    },
    {
        path: "/ground_lobby",
        title: "Ground Floor & Lobby",
        name: "ground_lobby"
    },
    {
        path: "/amenity_room",
        title: "Amenity Room",
        name: "amenity_room"
    },
    {
        path: "/pool_area",
        title: "Pool Area",
        name: "pool_area"
    },
    {
        path: "/corridors",
        title: "Corridors",
        name: "corridors"
    },
    {
        path: "/exit_stairs",
        title: "Exit Stairs",
        name: "exit_stairs"
    },
    {
        path: "/elevator_room",
        title: "Elevator Room",
        name: "elevator_room"
    },
    {
        path: "/electrical_closet",
        title: "Electrical Closet",
        name: "electrical_closet"
    },
    {
        path: "/mechanical",
        title: "Mechanical",
        name: "mechanical"
    },
    {
        path: "/parking_painting",
        title: "Parking Painting",
        name: "parking_painting"
    },
    {
        path: "/elevators",
        title: "Elevators",
        name: "elevators"
    },
    {
        path: "/garbage_recycling",
        title: "Garbage or Recycling",
        name: "garbage_recycling"
    },
    {
        path: "/mechanical_room",
        title: "Mechanical Room",
        name: "mechanical_room"
    },
    {
        path: "/elevators",
        title: "Elevators",
        name: "elevators"
    },

    {
        path: "/garage_exterior_door",
        title: "Garage or Exterior Door",
        name: "garage_exterior_door"
    },

]

class BreadcrumbTrail extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            crumbs: []
        }
    }

    componentDidMount()
    {
        if (this.props.location.pathname.length > 1)
        {
            const crumbs2 = [];
            const crumbs = this.props.location.pathname.split("/")
            for (let item of crumbs)
                if (item)
                    crumbs2.push(item);
            this.setState({ crumbs: crumbs2 });
            //console.log(this.state);
        }
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.location.pathname !== prevProps.location.pathname)
        {
            const crumbs = this.props.location.pathname.split("/");
            const newCrumb = crumbs[crumbs.length - 1];
            const prevCrumb = this.state.crumbs[this.state.crumbs.length - 2];

            if (this.props.location.pathname === '/' || this.props.location.pathname.includes('/search') || this.props.location.pathname.includes('/search_ce') )
                this.clearCrumbs();
            else if (prevCrumb && this.state.crumbs.indexOf(newCrumb) >= 0)
            {
                const crumbs2 = [];
                for (let item of crumbs)
                    if (item)
                        crumbs2.push(item);
                this.setState({ crumbs: crumbs2 });
            }
            else
            {
                const crumbs2 = this.state.crumbs;
                for (let item of crumbs)
                    if (item && crumbs2.indexOf(item) < 0)
                        crumbs2.push(item);
                this.setState({ crumbs: crumbs2 });
            }
        }
    }

    render()
    {
        const { location } = this.props
        let path = "";
        const crumbs = this.state.crumbs.map((crumb, i) =>
        {
            const matchedRoute = this.matchCrumbToRoute(routes, crumb);

            if (matchedRoute) 
            {
                path += matchedRoute.path;
                return (
                    <li key={i}>
                        {location.pathname !== crumb ? <Link to={path}>{matchedRoute.title}</Link> : matchedRoute.title}
                    </li>
                )
            } 
            else
            {
                return null
            }
        });

        if (crumbs && crumbs.length > 0)
            crumbs.unshift(<li title="Reset to Start" className="left_Bread" key="-1">
                <Link to="/">Home</Link>
            </li>);

        return (
            <ul className="tarion-cpg__breadcrumb-trail">
                {crumbs}
            </ul>
        )
    }

    clearCrumbs = () => this.setState({ crumbs: [] })

    addCrumb = crumb => this.setState(prevState => ({
        crumbs: [...prevState.crumbs, crumb]
    }))

    removeLastCrumb = () => this.setState(prevState => ({
        crumbs: prevState.crumbs.slice(0, (prevState.crumbs.length - 1))
    }))

    matchCrumbToRoute = (routes, crumb) => routes.find(route => route.name === crumb)
}

export default withRouter(BreadcrumbTrail)