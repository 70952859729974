/*eslint eqeqeq: 0*/

import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import loading from '../../images/loading.gif';
import axios from 'axios';
import BackButton from '../BackButton';
import A2 from './appendices/A2';
import ArticleFreehold from './ArticleFreehold';
import ArticleCE from './ArticleCE';


class Article extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            selectedArticle:null,
            crumbs: null,
            articles: [],
            filtered_articles: [],
            showArticle: false,
            loadedArticles: false,
            loadedArticle2: false,
            info_bottom: 0,
            article_bottom: 0,
            articleID: 0,
            singleArticle: props.match && props.match.params.id ? props.match.params.id : 0 
        }
        this.articleData = [];
        this.loading = true;
        this.SizingInterval = null;
        this.filteredList = [];

        //references are not meant to be path's
        //The below are separate paths meant to add categories to others.
        //pattern is CRUMB : Category from server.
        //CRUMB is always uppercase.
        //Use _ instead of spaces for room selectors
        this.reference= {
            //Obvious one
            TRIM: "Interior Finishing",
            'FINISHED FLOORING': "Flooring",
            'UNFINISHED FLOORING': "Flooring",
           // UNFINISHED_WALL: ["Foundation Basement", "Wall Framing"],
            'DOORS EXTERIOR': ["Exterior Doors" , "Exterior Air Filtration"],
            'DOORS INTERIOR': "Interior Doors",
           // WINDOWS: "Exterior Air Filtration",
            'EXTERIOR DOORS': "Exterior Air Filtration",
            // INTERIOR_DOORS: "Exterior Air Filtration",
            'EXTERIOR WINDOWS': ["Windows", "Exterior Air Filtration"],

            //Basement General
            'BASEMENT UNFINISHED': "Basement",
            'BASEMENT FINISHED': "Basement",

            //Because a condo unit is inside
            CONDO_UNIT: "Interior",
            TOWNHOME_UNIT: "Interior",

            //These 3 do not fit in interior finish and happen to all dryway
            //which is on walls and celing.
            'FINISHED WALL': ["Wall", "Wall Framing"],
            WALL: "Wall and Ceiling Finish",
            CEILING: "Wall and Ceiling Finish",

            //hallway is supposed to also get bedroom articles.
            //so prolly going to explicitly look for this.
            HALLWAY: "Bedroom",
            'EXTERIOR DOORS MAN': "Garage",

            //For CE stuff
            "BUILDING_EXTERIOR":"Building Envelope",
            "MECHANICAL_ROOM": "Generator Room",
            "ELEVATORS": ["Elevator Room", "Conveying Systems"],
            "CE WINDOWS": "Windows",
            "GARBAGE_RECYCLING": "Garbage Disposal",
            "EXTERIOR CLOSURE": "Exterior",
            "CE PLUMBING" : ["Plumbing", "Mechanical"],
            "AMENITY_ROOM" : "Amenity Washroom",
            "TOWNHOME" : "Common Element"
        }
    }

    componentDidMount()
    {
        const crumbs2 = [];
        const crumbs = this.props.location.pathname.split("/")
        for (let item of crumbs)
            if(item)
                crumbs2.push(item.toUpperCase());
        this.setState({ 'crumbs': crumbs2, 'selectedArticle':null });

        //which articles to load
        let both, ce, free = false;

        if (this.state.singleArticle)
            both = true;
    
        if ( 
            (crumbs2[1] === "COMMON_ELEMENT" || crumbs2[1] == "TOWNHOME")
        )
        {
            both = true;
        }
        else
        {
            free = true;
        }
            
        let articles = [];

        if(both)
        {
            axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/ce?_format=json&time=1066', method: 'get', responseType: 'json' } )
                .then((event) => {

                    if( (both && articles.length) || ce)
                    {
                        articles = articles.concat(event.data.sort(
                            function (a, b)
                            {
                                var left = a.number.split('.');
                                var right = b.number.split('.');
                                if (Number(left[0]) < Number(right[0]))
                                    return -1;
                                if (Number(left[0]) > Number(right[0]))
                                    return 1;
                                if (Number(left[1]) < Number(right[1]))
                                    return -1;
                                if (Number(left[1]) > Number(right[1]))
                                    return 1;
                                return 0;
                            }));
                        this.setState({ 'articles': articles
                            , 'loadedArticles' : true }); 

                        const filteredArticles = this.filterList();
                        this.setState({ filtered_articles: filteredArticles });
                    }
                    else if(both)
                    {
                        articles = articles.concat(event.data.sort(
                                function(a,b)
                                {
                                    var left = a.number.split('.');
                                    var right = b.number.split('.');
                                    if (Number(left[0]) < Number(right[0]))
                                        return -1;
                                    if (Number(left[0]) > Number(right[0]))
                                        return 1;
                                    if (Number(left[1]) < Number(right[1]))
                                        return -1;
                                    if (Number(left[1]) > Number(right[1]))
                                        return 1;
                                    return 0;
                                } ));
                        this.setState({ 'articles': articles
                            , 'loadedArticles': true});

                        const filteredArticles = this.filterList();
                        this.setState({ filtered_articles: filteredArticles });
                    }
                })
                .catch(function (event) { console.error('Loading common elements articles failed', event); } );
        }
                
        axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/freehold?_format=json&time=1086', method: 'get', responseType: 'json' } )
            .then((event) => { 
                
                if( (both && articles.length) || free)
                {
                    articles = articles.concat(event.data.sort(
                        function (a, b)
                        {
                            var left = a.number.split('.');
                            var right = b.number.split('.');
                            if (Number(left[0]) < Number(right[0]))
                                return -1;
                            if (Number(left[0]) > Number(right[0]))
                                return 1;
                            if (Number(left[1]) < Number(right[1]))
                                return -1;
                            if (Number(left[1]) > Number(right[1]))
                                return 1;
                            return 0;
                        }));
                    this.setState({ 'articles': articles
                        , 'loadedArticles' : true }); 

                    const filteredArticles = this.filterList();
                    this.setState({ filtered_articles: filteredArticles });
                }
                // @todo: this appears to not do anything, once this logic
                // is understood (including the other axios calls and if
                // statements), revise and remove this if unnecessary.
                else
                {
                    articles = articles.concat(event.data.sort(
                        function (a, b)
                        {
                            var left = a.number.split('.');
                            var right = b.number.split('.');
                            if (Number(left[0]) < Number(right[0]))
                                return -1;
                            if (Number(left[0]) > Number(right[0]))
                                return 1;
                            if (Number(left[1]) < Number(right[1]))
                                return -1;
                            if (Number(left[1]) > Number(right[1]))
                                return 1;
                            return 0;
                        }));
                }
            })
            .catch(function (event) { console.error('Loading freehold articles failed', event); } );

        this.SizingInterval = setInterval(() =>
        {
            if (document.querySelector("#article_info"))
            {
                this.setState({
                    'article_bottom': document.querySelector("#last_p_article_info").getBoundingClientRect().bottom,
                    'info_bottom': document.querySelector("#article_info").getBoundingClientRect().bottom
                });
                clearInterval(this.SizingInterval);
            }
        }, 1000);
    }

    selectArticle(val, index, appendix = null)
    {
        if (val)
        {
            if (this.state.showSideBar)
                this.setState({ 'showSideBar': false, "articleID" : index });
            if (this.articleData[val])
            {
                this.setState({ 'selectedArticle': this.articleData[val], "articleID": index });
            }
            else
            {
                if(this.state.loadedArticle2)
                    this.setState({loadedArticle2: false});
                axios({ url: process.env.REACT_APP_API_URI + '/api/cpg/article?nid=' + val + '&_format=json', method: 'get', responseType: 'json' })
                    .then((event) => { this.setState({ 'loadedArticle2': true,'showArticle': true, 'selectedArticle': event.data[0], "articleID": index, articleData: { [val]: event.data[0], ...this.articleData} }) })
                    .catch((event) => { console.error('Loading article failed', event); this.setState({ 'selectedArticle': null, "articleID": index }); });
            }
        }
        else if (appendix)
        {
            this.setState({ 'showArticle': true, 'selectedArticle': appendix, "articleID": index });
        }
    }

    //This function takes the full article list and displays it.
    filterList()
    {
        let filteredList = [];

        //Return single article if the id was set.
        //Not using state as it can be really fast getting here. 
        if (this.props.match && this.props.match.params.id)
            for (let i = 0, len = this.state.articles.length; i < len; i++)
                if (this.state.articles[i].id == this.state.singleArticle)
                {
                    return [this.state.articles[i]];
                }

        //if empty crumbs just return nothing.
        if(!this.state.crumbs)
            return [];

        let crumbs = this.state.crumbs;

        if(this.state.crumbs[1] == "COMMON_ELEMENT")
        {
            if (this.state.crumbs[3] == "EXTERIOR_CLOSURE" && this.state.crumbs[4] != "GENERAL")
            {
                crumbs = ['FREEHOLD','EXTERIOR'];
                for(let i = 4; i < this.state.crumbs.length; i++)
                    crumbs.push(this.state.crumbs[i]);
            }

            if (this.state.crumbs[2] == "AMENITY_ROOM" && this.state.crumbs[3] == "INTERIOR_FINISHES" && this.state.crumbs[4] != "GENERAL") 
            {
                crumbs = ['FREEHOLD', 'INTERIOR', 'KITCHEN'];
                for (let i = 4; i < this.state.crumbs.length; i++)
                    crumbs.push(this.state.crumbs[i]);
            }

            if (this.state.crumbs[2] == "PARKING_GARAGE" && this.state.crumbs[3] == "GARAGE_EXTERIOR_DOOR" && this.state.crumbs[4] == "EXTERIOR_DOORS") 
            {
                crumbs = ['CONDO','COMMON_ELEMENT', 'PARKING_GARAGE', 'EXTERIOR_DOORS'];
            }
            if (this.state.crumbs[2] == "PARKING_GARAGE" && this.state.crumbs[3] == "GARAGE_EXTERIOR_DOOR" && this.state.crumbs[4] == "EXTERIOR_DOORS_MAN") 
            {
                crumbs = ['FREEHOLD', 'EXTERIOR', 'EXTERIOR_DOORS_MAN'];
            }
        }

        if (this.state.crumbs[1] == "TOWNHOME")
        {
            if (this.state.crumbs[3] == "EXTERIOR_CLADDING" || this.state.crumbs[3] == "EXTERIOR_PAINT"
                || this.state.crumbs[3] == "FOUNDATION"
                || this.state.crumbs[3] == "LANDING"
                || this.state.crumbs[3] == "EXTERIOR_COLUMNS"
                || this.state.crumbs[3] == "EXTERIOR_DOORS"
                || this.state.crumbs[3] == "EXTERIOR_DOORS_MAN"
            )
                crumbs = ['FREEHOLD', 'EXTERIOR', this.state.crumbs[3], this.state.crumbs[4] ? this.state.crumbs[4] : ''];
        }

        /*
            crumb format
            0 - condo or freehold
            1 - inside or outside (inout)
            2 - room selector (room)
            3 - category selector (category)
            4 - category selector filter ie(vinyl flooring)

            Some article's have to appear both inside and outside. For example some electrical articles. To do this these articles have a room selector of electrical and a category of electrical.
            /freehold/exterior/electrical
            /freehold/interior/-room-/electrical
            The problem occurs when say "bedroom" = /= electrical and an article about electrical outlets doesn't show up.
        */
        for(let item of this.state.articles)
        {
            item.title = item.title.replace(/&#039;/g, "'");
            item.title = item.title.replace(/&quot;/g, "\"");

            //show override as some articles only show up in one very specific scenario
            if(item.show)
            {
                let arrShow = item.show.split(";");
                let i = 0;
                let match = false;
                arrShow.forEach(show => {
                    let arrItem = show.split(",");
                    i = 0;
                    while( i < arrItem.length )
                    {
                        if(arrItem[i])
                        {
                            if (arrItem[i].toUpperCase() !== this.state.crumbs[i + 1])
                                break;
                        }
                        i++;
                    }
                    if (i == arrItem.length)
                        match = true;
                });
                if (match)
                {
                    filteredList.push(item);
                    continue;
                }
            }

            //hide override as somearticles only hide in one very specific scenario
            if(item.hide)
            {
                let arrHide = item.hide.split(";");
                let i = 0;
                let match = false;
                arrHide.forEach(show =>
                {
                    let arrItem = show.split(",");
                    i = 0;
                    while (i < arrItem.length)
                    {
                        if (arrItem[i])
                        {
                            if (arrItem[i].toUpperCase() !== this.state.crumbs[i + 1])
                                break;
                        }
                        i++;
                    }
                    if (i == arrItem.length)
                        match = true;
                });
                if (match)
                    continue;
            }

            if ( 
                (
                    (this.state.crumbs[2] == "PARKING_GARAGE" && (this.state.crumbs[3] == "ELECTRICAL" || this.state.crumbs[3] == "PLUMBING")) ||
                    (this.state.crumbs[2] == "MECHANICAL_ROOM" && this.state.crumbs[3] == "PLUMBING") ||
                    (this.state.crumbs[2] == "GARBAGE_RECYCLING" && this.state.crumbs[3] == "MECHANICAL") ||
                    (this.state.crumbs[2] == "CORRIDORS" && this.state.crumbs[3] == "PLUMBING")
                )
                && item.type == "Construction Performance Guidelines"){
                continue;
            }            

            let match = null;
            //it is built to fall through as blank means N/A.
            //crumb is forced uppercase so this is a case insensitive comparison
            if ( typeof item.inout !== "undefined" && (!item.inout || item.inout.toUpperCase().includes(crumbs[1].replace("_", " ")) || this.checkReference(item.inout, this.reference[crumbs[1]])))
            {
                //Another case insensitive comparison, this time with reference used.
                //Is a way to store in one place alternative reference ie. Hallway should show all bedroom articles
                match = item.inout.toUpperCase().includes(crumbs[1].replace("_", " ")) || this.checkReference(item.inout, this.reference[crumbs[1]]);

                //Articles do not need the inout selector.
                //Move on if inout exists and matches or if it doesnt exist at all.
                if(match || !item.inout)
                {
                    //again store if it actually matched
                    //doesnt matter if inout succeeded if room fails and one does not get here if it doesn't match.
                    match = !item.room ? match : item.room.toUpperCase().includes(crumbs[2].replace("_", " ")) || this.checkReference(item.room, this.reference[crumbs[2]]);

                    //exterior cladding was breaking everything.
                    //is specical as it is the only one that starts the filter on name at crumb 3 instead of 4.
                    if(item.room.includes("Exterior Cladding") && crumbs[2] === "EXTERIOR_CLADDING")
                    {
                        if (item.floor_side.toUpperCase().includes(crumbs[3].replace(/_/g, " ")) || (item.floor_side === "General"))
                        {
                            filteredList.push(item);
                        }
                    }
                    //Item has to have a prior match or not have a inout tag or
                    else if( (
                            (match || (!item.room && !item.inout) || (crumbs[3] == "INTERIOR_FINISHES"))
                            ||
                                //SOme articles show inside and outside and electrical is a room selector for outside.
                                //So it requires a check.
                                (crumbs[3] && ( crumbs[3] === "ELECTRICAL" || crumbs[3] === "COLD_ELECTRICAL") &&
                                    item.room === "Electrical")
                            ||
                                //I added this cause it needs to show exterior doors, but living room is not exterior doors
                                //and adding that reference would causes wrong articles to appear
                                (crumbs[3] && crumbs[3] === "DOORS_EXTERIOR" &&
                                    item.room === "Exterior Doors")
                            ||
                                //requires special handling as this effectively only shows garage doors
                                (crumbs[2] && crumbs[2] === "EXTERIOR_DOORS_MAN" &&
                                    item.room.includes("Garage") )
                            )
                            && item.category && (crumbs[3] || (!crumbs[3] && crumbs[2]))
                            //these options below are regions where no category matters and only exist
                            //because there are cases where category doesn't matter no matter what
                            && !(item.room.includes("Attic") && crumbs[2] === "ATTIC")
                            && !(item.room.includes("Foundation") && crumbs[2] === "FOUNDATION")
                            && !(item.room.includes("Landing") && crumbs[2] === "LANDING")
                            && !(item.room.includes("Exit Stairs") && crumbs[2] === "EXIT_STAIRS")
                            && !(item.room.includes("Elevator Room") && crumbs[2] === "ELEVATORS")
                            
                            && !(item.room.includes("Balcony") && crumbs[2] === "BALCONY" && crumbs[1] === "TOWNHOME")
                            && !(item.room.includes("Balcony") && crumbs[2] === "BALCONY" && crumbs[1] === "TOWNHOME")
                        )
                    {
                        //there are a couple paths where there is no category selector
                        //ie freehold/exterior/electrical
                        //in which case the room selector is taken as teh category.
                        let crumb = crumbs[3] ? crumbs[3] : crumbs[2];
                        crumb = crumb.replace(/_/g, " ");
                        
                        let arrCat = item.category.toUpperCase();

                        //find the category that matches your article.
                        if(!Array.isArray(this.reference[crumb]) && (this.checkReference(arrCat, crumb) || (this.reference[crumb] && this.checkReference(arrCat, this.reference[crumb].toUpperCase()))) )
                        {
                            if(crumb == "INTERIOR FINISHES")
                            {
                                let room = item.room_extra ? item.room_extra : item.room;
                                match = room.toUpperCase().includes(crumbs[2].replace("_", " ")) || this.checkReference(room, this.reference[crumbs[2]]);
                                if(match)
                                {
                                    crumb = crumbs[4];
                                    crumb = crumb.replace(/_/g, " ");
                                    if (!Array.isArray(this.reference[crumb]) && (this.checkReference(arrCat, crumb) || (this.reference[crumb] && this.checkReference(arrCat, this.reference[crumb].toUpperCase()))))
                                    {
                                        if (crumbs[5])
                                        {
                                            if( (item.floor_side.toUpperCase().includes(crumbs[5].replace(/_/g, " ")) || (item.floor_side.includes("General") && crumbs[5] === "GENERAL")) && item.type == "Construction Performance Guidelines")
                                            {
                                                filteredList.push(item);
                                            }
                                        }
                                        else
                                        {
                                            if (item.number)
                                                filteredList.push(item);
                                        }
                                    }
                                }
                            }
                            //some few items have a category filter
                            //primarily flooring as cladding has only 4 crumbs.
                            //this just checks to see if teh crumb exists in the title anywhere.
                            //ARTICLE check added because General.
                            else if (crumbs[4] && item.type == "Construction Performance Guidelines")
                            {
                                if(item.floor_side.toUpperCase().includes(crumbs[4].replace(/_/g, " ")) || ( item.floor_side.includes("General") && crumbs[4] === "GENERAL" ) )
                                {
                                    filteredList.push(item);
                                }
                                else if ( 
                                    (crumbs[2] == "BUILDING_EXTERIOR" && crumb == "EXTERIOR_CLOSURE" && crumbs[4] == "GENERAL") ||
                                    (crumbs[2] == "GARBAGE_RECYCLING" && crumb == "INTERIOR_FINISHES" && crumbs[4] == "GENERAL")
                                ){
                                    filteredList.push(item);
                                }   
                            }
                            else
                            {
                                if(item.number)
                                    filteredList.push(item);
                            }
                        }
                        //there are a couple that have to have multiple responses in references.
                        //this simply goes through all of them to find a matching one.
                        else if (Array.isArray(this.reference[crumb]) && this.reference[crumb])
                        {
                            let arrRef = this.reference[crumb];
                            for(let ref of arrRef)
                            {
                                if (arrCat.includes(ref.toUpperCase()))
                                {
                                    if(item.number)
                                        filteredList.push(item);
                                    break;
                                }
                            }
                        }
                    }
                    //If a tag matched, display it.
                    else if(match)
                    {
                        if(item.number)
                            filteredList.push(item);
                    }
                }
            }
        }

        return filteredList;
    }

    checkReference(value, check)
    {
        if(!check)
            return false;
        if(check instanceof Array)
        {
            let arrValue = value.split(", ");
            for(let item of arrValue)
                for(let item2 of check)
                    if(item2 == item.trim())
                        return true;
        }
        else
        {
            let arrValue = value.split(", ");
            for (let item of arrValue)
                    if (check == item.trim())
                        return true;
        }
        return false;
    }

    getRelatedArticles()
    {
        let data = [];
        let filteredData = [];
        for (let item of this.state.selectedArticle.related.split(","))
            data.push(Number(item));

        for (let item2 of this.state.articles)
        {
            if (data.includes(Number(item2.id)))
                filteredData.push(<p key={item2.id} onClick={() => this.selectArticle(item2.id, 0)} className="link">{item2.title}</p>);
        }

        if (this.state.selectedArticle.remarks.includes("A2") || this.state.selectedArticle.performance.includes("A2"))
            filteredData.push(<p key="A2" onClick={() => this.selectArticle(null,0, "A2")} className="link">Appendix A2</p>);
        if (this.state.selectedArticle.remarks.includes("A3") || this.state.selectedArticle.performance.includes("A3"))
            filteredData.push(<p key="A3" onClick={() => this.selectArticle(null,0, "A3")} className="link">Appendix A3</p>);
        if (this.state.selectedArticle.remarks.includes("A4") || this.state.selectedArticle.performance.includes("A4"))
            filteredData.push(<p key="A4" onClick={() => this.selectArticle(null,0, "A4")} className="link">Appendix A4</p>);
        if (this.state.selectedArticle.remarks.includes("A5") || this.state.selectedArticle.performance.includes("A5"))
            filteredData.push(<p key="A5" onClick={() => this.selectArticle(null,0, "A5")} className="link">Appendix A5</p>);
        if (this.state.selectedArticle.remarks.includes(" A6") || this.state.selectedArticle.performance.includes("A6"))
            filteredData.push(<p key="A6" onClick={() => this.selectArticle(null,0, "A6")} className="link">Appendix A6</p>);

        return filteredData;
    }

    getPreviousArticle()
    {
        if(this.state.articleID > 0)
            this.selectArticle(this.state.filtered_articles[this.state.articleID - 1].id, this.state.articleID - 1);
        else
            this.selectArticle(this.state.filtered_articles[this.state.filtered_articles.length - 1].id, this.state.filtered_articles.length -1);
    }

    getNextArticle()
    {
        if (this.state.articleID < this.state.filtered_articles.length - 1)
            this.selectArticle(this.state.filtered_articles[this.state.articleID + 1].id, this.state.articleID + 1);
        else
            this.selectArticle(this.state.filtered_articles[0].id, 0);
    }

    showSidebar(evt)
    {
        this.setState({ 'showSideBar': !this.state.showSideBar });
        evt.stopPropagation();
    }

    hideSideBar()
    {
        if(this.state.showSideBar)
            this.setState({ 'showSideBar': false });
    }

    render() {
        var settings = {
            dots: true,
            slidesToShow: 1,
        }

        if (this.state.singleArticle && !this.state.articleID)
        {
            for (let i = 0, len = this.state.articles.length; i < len; i++)
                if (this.state.articles[i].id == this.state.singleArticle)
                {
                    this.selectArticle(this.state.singleArticle, i);
                    break;
                }
        }

        const opts = {
            height: '190',
            width: '294',
        };

        let beep = null;
        if(this.state.filtered_articles)
        {
            const articleList = this.state.filtered_articles;
            if(!this.state.showArticle && articleList.length)
                this.selectArticle(articleList[0].id,0);
            beep = articleList.map((item, i) => (
                <p key={item.id} 
                    onClick={() => this.selectArticle(item.id, i)} 
                    className={`link ${this.state.selectedArticle && this.state.selectedArticle.nid && this.state.selectedArticle.nid == item.id ? 'active' : ''}`}
                >
                    <span>{item.type == "CE Construction Performance Guidelines" ? "CE " : "FH "}</span>
                    {item.number}: {item.title}
                </p>
            ));
            if(articleList.length === 0)
                beep = <p>No Articles Found</p>
            else
                this.loading = false;
        }
        else
            beep = <img src={loading} alt="loading" className="loading_img img" />;

        let img_srcs = {
            src0: null,
            src1: null,
            src2: null,
            src3: null,
            src4: null
        }
        if(this.state.selectedArticle && this.state.selectedArticle.images)
        {
            let arrImg = this.state.selectedArticle.images.split(",");
            for(var i = 0, len = arrImg.length; i < len; i++ )
                img_srcs['src' + i] = <img src={process.env.REACT_APP_API_URI + arrImg[i].trim()} alt={'Image ' + (i + 1)}/>;
        }

        let showDisc = this.props.location.pathname.includes("townhome/") || this.props.location.pathname.includes("common_element") || this.props.location.pathname.includes("/common_element/");

        return (
           <div className="articleContainer" onClick={() => this.hideSideBar()}>
                <p className="article_back_button">
                    <BackButton />
                </p>
                <div className="mobile_article_sidebar" onClick={(evt) => this.showSidebar(evt)}>
                    <svg className="menu_breadcrumb" height="30" width="30" viewBox="0 0 30 30">
                        <line x1="0" y1="3" x2="30" y2="3" />
                        <line x1="0" y1="15" x2="30" y2="15" />
                        <line x1="0" y1="27" x2="30" y2="27" />
                    </svg>
                </div>
                <div className={`article_sidebar ${this.loading ? 'center' : ''} ${this.state.showSideBar ? 'mobile_visible' : ''} `}>
                    {beep}
                </div>
                { typeof this.state.selectedArticle !== "string" ?
                    this.state.selectedArticle && (this.state.selectedArticle.article_number || this.state.selectedArticle.ce_article_number) && this.state.loadedArticle2 ?
                        this.state.selectedArticle.type === "Construction Performance Guidelines" ?          
                            <ArticleFreehold article_data={this.state.selectedArticle} 
                                article_bottom={this.state.article_bottom} 
                                info_bottom={this.state.info_bottom}
                                img_srcs={img_srcs}
                                related_articles={this.getRelatedArticles()}
                                settings={settings}
                                opts={opts}
                                showDisc={showDisc}  
                            />
                        : // this.state.selectedArticle.type === "Construction Performance Guidelines" ? 
                            <ArticleCE article_data={this.state.selectedArticle}
                                article_bottom={this.state.article_bottom}
                                info_bottom={this.state.info_bottom}
                                img_srcs={img_srcs}
                                related_articles={this.getRelatedArticles()}
                                settings={settings}
                                opts={opts}                                
                            />
                    : // this.state.selectedArticle && this.state.selectedArticle.article_number ?
                        <div className="article_loading">
                            <img src={loading} alt="loading" className="loading_img img" />
                        </div>
                ://typeof this.state.selectedArticle !== "string" ? --- END
                    <A2 appendix={this.state.selectedArticle} />
                }
                <div className="prev_next">
                    <div> 
                        <span onClick={() => this.getPreviousArticle()}>&lsaquo;</span> 
                        <p onClick={() => this.getPreviousArticle()}>Previous</p>
                    </div>
                    <div>
                        <p onClick={() => this.getNextArticle()}>Next</p> 
                        <span onClick={() => this.getNextArticle()}>&rsaquo;</span>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Article)
