/* eslint import/no-webpack-loader-syntax: off */

import Category from '../../Category'

//import Exterior from '../../images/mobile_svgs/condo/exterior_doors.svg';
import Garage from '../../../images/mobile_svgs/garage-door.svg';
import ExteriorDoors from '../../../images/mobile_svgs/exterior-door.svg';

const title = "Select an area"
const navItems = [
   // {
     //   path: "exterior/general",
       // title: "General",
        //image: Exterior
    //},
    {
        path: "garage_exterior_door/exterior_doors",
        title: "Exterior Doors",
        image: ExteriorDoors
    },
    {
        path: "garage_exterior_door/exterior_doors_man",
        title: "Garage & Man Doors",
        image: Garage
    }
]

export default Category(title, navItems, true, false)