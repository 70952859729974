/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../WithMobile';
import * as functions from '../functions';

//import CondoImage from '../../images/CommonArea.svg'
import CondoImage from '../../images/CommonArea.min.svg'

import Lobby from '../../images/mobile_svgs/condo/lobby.svg';
import Exterior from '../../images/mobile_svgs/condo/exterior.svg';
import Parking from '../../images/mobile_svgs/condo/parking.svg';
import Garbage from '../../images/mobile_svgs/condo/garbage-recycling.svg';
import Pool from '../../images/mobile_svgs/condo/Pool.svg';
import Corridors from '../../images/mobile_svgs/condo/corridors.svg';
import Stairs from '../../images/mobile_svgs/condo/stairs_1.svg';
import Elevator from '../../images/mobile_svgs/condo/elevator.svg';
import Utility from '../../images/mobile_svgs/condo/mechanical.svg';
import Amenity from '../../images/mobile_svgs/condo/Amenities.svg';

import { Redirect } from 'react-router'

const Colors = [['parking_garage', false], ['building_exterior', false], ['ground_lobby', false],
    ['garbage_recycling', false], ['pool_area', false], ['corridors', false], ['exit_stairs', false], ['elevators', false],
    ['mechanical_room', false], ['barrier_free', false], ['amenity_room', false]
];
const title = "Select a view"
const navItems = [
    {
        path: "common_element/ground_lobby",
        title: "Ground Floor or Lobby",
        class: "ground_lobby",
        highlight_mode: 2
    },
    {
        path: "common_element/building_exterior",
        title: "Building Exterior",
        class: "building_exterior",
        highlight_mode: 2
    },
    {
        path: "common_element/parking_garage",
        title: "Parking or Garage",
        class: "parking_garage",
        highlight_mode: 2
    },
    {
        path: "common_element/garbage_recycling",
        title: "Garbage or Recycling",
        class: "garbage_recycling",
        highlight_mode: 2
    },
    {
        path: "common_element/pool_area",
        title: "Pool Area",
        class: "pool_area",
        highlight_mode: 2
    },
    {
        path: "common_element/corridors",
        title: "Corridors",
        class: "corridors",
        highlight_mode: 2
    },
    {
        path: "common_element/exit_stairs",
        title: "Exit Stairs",
        class: "exit_stairs",
        highlight_mode: 2
    },
    {
        path: "common_element/elevators",
        title: "Elevators",
        class: "elevators",
        highlight_mode: 2
    },
    {
        path: "common_element/mechanical_room",
        title: "Mechanical Room",
        class: "mechanical_room",
        highlight_mode: 2
    },
    {
        path: "common_element/amenity_room",
        title: "Amenity Room",
        class: "amenity_room",
        highlight_mode: 2
    }
]


class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "common_element/" + item });
        }
    }

    onMobileClick(item)
    {
        this.setState({ redirect: true, redirectTo: item });
    }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors, 2))} onClick={(e) => this.onMouseClick(e)} className="house tarion-cpg__svg-container pointer">
                    <CondoImage />
                </div>
            </div> :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('common_element/ground_lobby')} className="tarion-cpg__svg-container">
                    <Lobby />
                    <p>Ground Floor or Lobby</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/building_exterior')} className="tarion-cpg__svg-container">
                    <Exterior />
                    <p>Building Exterior</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/parking_garage')} className="tarion-cpg__svg-container">
                    <Parking />
                    <p>Parking or Garage</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/garbage_recycling')} className="tarion-cpg__svg-container">
                    <Garbage />
                    <p>Garbage or Recycling</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/pool_area')} className="tarion-cpg__svg-container">
                    <Pool />
                    <p>Pool Area</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/corridors')} className="tarion-cpg__svg-container">
                    <Corridors />
                    <p>Corridors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/exit_stairs')} className="tarion-cpg__svg-container">
                    <Stairs />
                    <p>Exit Stairs</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/elevators')} className="tarion-cpg__svg-container">
                    <Elevator />
                    <p>Elevators</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/mechanical_room')} className="tarion-cpg__svg-container">
                    <Utility />
                    <p>Mechanical Room</p>
                </div>
                <div onClick={(e) => this.onMobileClick('common_element/amenity_room')} className="tarion-cpg__svg-container">
                    <Amenity />
                    <p>Amenity Room</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}


export default Container;