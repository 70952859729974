/*eslint eqeqeq: 0*/

//React tries to force using === for no reason.
//The issue results in that js is inherently not type safe and so === fails alot
//when "2" === 2 comes up. Due to this I add the above to keep react from whining about
//the fact that in js type does not matter.

export default {
     /*
        Highlighting in this is class based.
        In effect the system checks the classes of teh item you are hovering over against a array of Colors of type [~name~,~visible~]
        So for every SVG it has teh classes that match teh ones in Colors inside it. When you hover over an item
        it checks to see if the class you are hovering over is inside the array Colors., If so, is it already visible.
        And if not visible Set visible and add a highlight to all members of that class.
    */
    onMove(e, Colors, mode)
    {
        e.persist();
        let classes = null;
        if (!e.target.className && !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal ? e.target.className.baseVal.toString() : e.target.className.toString();
        //console.log(classes);
        if (classes && this.findColors(classes, Colors))
            this.setColors(classes, Colors,mode);
        else
            this.resetColors(Colors, null, mode);
    },
    findColors(val, Colors)
    {
        for (let item of Colors)
            if (val && val.includes(item[0]))
                return true;
    },
    //I have two different settings here.
    //For all non rooms, an overlay to highlight, therefore opacity is crucial for those.
    //The room selection screens are the items themselves so no opacity.
    setColors(val,Colors,mode)
    {
        if(mode == 1){}
        else
        {
            let add = "";
            for(let item of Colors)
            {
                //first check cannot be exact as val may be like "exterior_doors p_link"                
                if(val.includes(item[0]))
                {
                    let classVar = val.split(" ");
                    let found = false;
                    for (let item2 of classVar)
                    {
                        if (item2 == item[0])
                        {
                            found= true;
                            item[1] = true;
                            add += item[0] + " ";
                        }
                    }
                    if(!found && item[1])
                    {
                        item[1] = false;
                    }
                }
                else if(item[1])
                {
                    item[1] = false;
                }
            }

            if(add)
            {
                let found = document.querySelector('#tarion-cpg__main');
                found.className = found.className != add.trim() ? add.trim() : found.className;
            }           
        }
    },
    resetColors(Colors, ignore, mode)
    {
        //legacy from old method of highlighting. 
        //Kept arround for when tarion decides they want a 3rd way of highlighting
        if(mode == 1){}
        else
        {
            //just remove the class and reset all colors.
            document.querySelector('#tarion-cpg__main').className = "";
            for (let item of Colors)
                item[1] = false;
        }
    },
    //Was orginally a tag stripping function. That functionality has been removed and
    //now all it does is convert quotes ' and & from html.
    stripTags(value)
    {
        value = value.replace(/&amp;/g, "&");
        value = value.replace(/&#039;/g, "'");
        value = value.replace(/&quot;/g, '"');
        return value;
    }
}