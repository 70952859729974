/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from './WithMobile'
import { Redirect } from 'react-router'
//import House from '../images/Freeholds.svg';
import House from '../images/Freeholds.min.svg';

import MobInterior from '../images/mobile_svgs/freehold-inside.svg';
import MobExterior from '../images/mobile_svgs/freehold-outside.svg';

import * as functions from './functions';

const Colors = [['interior', false], ['exterior', false]];
const title = "Select a view"
const navItems = [
    {
        path: "/freehold/exterior",
        title: "Exterior",
        class: "exterior",
        highlight_mode: 2
    },
    {
        path: "/freehold/interior",
        title: "Interior",
        class: "interior",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
            this.setState({ redirect: true, redirectTo: "freehold/" + classes.split(" ")[0] });
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div onMouseMove={((e) => functions.default.onMove(e, Colors))} className="tarion-cpg__container">
                <div onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <House />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container">
                <div onClick={(e) => this.onMobileClick('freehold/exterior')} className="tarion-cpg__svg-container">
                    <MobExterior />
                    <p>Exterior</p>
                </div>
                <div onClick={(e) => this.onMobileClick('freehold/interior')} className="tarion-cpg__svg-container">
                    <MobInterior />
                    <p>Interior</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>
        
        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}

export default Container;