/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../WithMobile';
import Condo from '../../images/Condo-Interior.svg'

import MobEntry from '../../images/mobile_svgs/Entry-Way.svg';
import MobLaundry from '../../images/mobile_svgs/Condo-Laundry.svg';
import MobBedroom from '../../images/mobile_svgs/Bedroom.svg';
import MobBathroom from '../../images/mobile_svgs/Bathroom.svg';
import MobKitchen from '../../images/mobile_svgs/Kitchen.svg';
import MobLivingRoom from '../../images/mobile_svgs/Condo-Living-Room.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['entry', false], ['bathroom', false], ['bedroom', false], ['kitchen', false],
    ['living_room', false], ['laundry', false]
];
const title = "Select a room"


class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }        
        this.updatePredicate = this.updatePredicate.bind(this);

        this.path = this.props.location.pathname.split("/")[2];
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: this.path + "/" + item });
        }
    }
    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        const navItems = [
            {
                path: this.path + "/entry",
                title: "Entry",
                class: "entry",
                highlight_mode: 2
            },
            {
                path: this.path + "/bathroom",
                title: "Bathroom",
                class: "bathroom",
                highlight_mode: 2
            },
            {
                path: this.path + "/bedroom",
                title: "Bedroom",
                class: "bedroom",
                highlight_mode: 2
            },
            {
                path: this.path + "/living_room",
                title: "Living Room",
                class: "living_room",
                highlight_mode: 2
            },
            {
                path: this.path + "/kitchen",
                title: "Kitchen",
                class: "kitchen",
                highlight_mode: 2
            },
            {
                path: this.path + "/laundry",
                title: "Laundry",
                class: "laundry",
                highlight_mode: 2
            }
        ];


        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container condo_unit2 pointer">
                    <Condo />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick(this.path + '/entry')} className="tarion-cpg__svg-container">
                    <MobEntry />
                    <p>Entry</p>
                </div>
                <div onClick={(e) => this.onMobileClick(this.path + '/bathroom')} className="tarion-cpg__svg-container">
                    <MobBathroom />
                    <p>Bathroom</p>
                </div>
                <div onClick={(e) => this.onMobileClick(this.path + '/bedroom')} className="tarion-cpg__svg-container">
                    <MobBedroom />
                    <p>Bedroom</p>
                </div>
                <div onClick={(e) => this.onMobileClick(this.path + '/living_room')} className="tarion-cpg__svg-container">
                    <MobLivingRoom />
                    <p>Living Room</p>
                </div>
                <div onClick={(e) => this.onMobileClick(this.path + '/kitchen')} className="tarion-cpg__svg-container">
                    <MobKitchen />
                    <p>Kitchen</p>
                </div>
                <div onClick={(e) => this.onMobileClick(this.path + '/laundry')} className="tarion-cpg__svg-container">
                    <MobLaundry />
                    <p>Laundry Room</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container