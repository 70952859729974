/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../WithMobile';

//import Outside from '../../images/Freehold_Exterior.svg';
import Outside from '../../images/Freehold_Exterior.min.svg';

import MobRoof from '../../images/mobile_svgs/Roof.svg';
import MobFoundation from '../../images/mobile_svgs/Foundation.svg';
import MobElectrical from '../../images/mobile_svgs/electrical.svg';
import MobCladding from '../../images/mobile_svgs/Siding.svg';
import MobPaint from '../../images/mobile_svgs/Paint.svg';
import MobSteps from '../../images/mobile_svgs/Landing-steps.svg';
import MobWindows from '../../images/mobile_svgs/Windows.svg';
import MobColumn from '../../images/mobile_svgs/Column.svg';
import MobDoors from '../../images/mobile_svgs/Doors.svg';
import MobGarage from '../../images/mobile_svgs/Garage.svg';
import MobLandscape from '../../images/mobile_svgs/Landscape-Deck.svg';

import { Redirect } from 'react-router'
import * as functions from '../functions';

const Colors = [
    ['roof', false], ['electrical', false], ['exterior_cladding', false], ['exterior_paint', false],
    ['exterior_wood', false], ['water_penetration', false], ['foundation', false], ['landing', false],
    ['exterior_windows', false], ['filtration', false], ['fireplace', false], ['exterior_doors', false],
    ['exterior_doors_man', false],
    ['landscape_deck', false], ['attic', false], ['garage', false], ['exterior_columns', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "exterior/roof",
        title: "Roof",
        class: "roof",
        highlight_mode: 2
    },
    {
        path: "exterior/electrical",
        title: "Electrical",
        class: "electrical",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_cladding",
        title: "Exterior Cladding",
        class: "exterior_cladding",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_paint",
        title: "Exterior Paint",
        class: "exterior_paint",
        highlight_mode: 2
    },
    {
        path: "exterior/foundation",
        title: "Foundation",
        class: "foundation",
        highlight_mode: 2
    },
    {
        path: "exterior/landing",
        title: "Landing or Steps",
        class: "landing",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_windows",
        title: "Exterior Windows",
        class: "exterior_windows",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_columns",
        title: "Exterior Columns",
        class: "exterior_columns",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_doors",
        title: "Exterior Doors",
        id: "exterior_doors",
        mode_selected: "interior",
        mobile_hide: true,
        class: "exterior_doors exterior_doors_man",
        highlight_mode: 2
    },
    {
        path: "exterior/exterior_doors",
        title: "Exterior Doors",
        id: "exterior_doors",
        mobile_show: true,
        class: "exterior_doors exterior_doors_man",
    },
    {
        path: "exterior/exterior_doors_man",
        title: "Garage & Man Doors",
        id: "exterior_doors",
        mobile_show: true,
        class: "exterior_doors exterior_doors_man",
    },
    {
        path: "exterior/landscape_deck",
        title: "Landscape or Deck",
        class: "landscape_deck",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
            this.setState({ redirect: true, redirectTo: "exterior/" + classes.split(" ")[0] });
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div onMouseMove={((e) => functions.default.onMove(e, Colors))} className="tarion-cpg__container freehold_exterior">
                <div onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <Outside />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                
                <div onClick={(e) => this.onMobileClick('exterior/electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>                
                <div onClick={(e) => this.onMobileClick('exterior/landscape_deck')} className="tarion-cpg__svg-container">
                    <MobLandscape />
                    <p>Landscape or Deck</p>
                </div>                
                <div onClick={(e) => this.onMobileClick('exterior/exterior_columns')} className="tarion-cpg__svg-container">
                    <MobColumn />
                    <p>Exterior Columns</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_windows')} className="tarion-cpg__svg-container">
                    <MobWindows />
                    <p>Exterior Windows</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_cladding')} className="tarion-cpg__svg-container">
                    <MobCladding />
                    <p>Exterior Cladding</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_doors')} className="tarion-cpg__svg-container">
                    <MobDoors />
                    <p>Exterior Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/foundation')} className="tarion-cpg__svg-container">
                    <MobFoundation />
                    <p>Foundation</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/exterior_doors_man')} className="tarion-cpg__svg-container">
                    <MobGarage />
                    <p>Garage & Man Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/landing')} className="tarion-cpg__svg-container">
                    <MobSteps />
                    <p>Landing or Steps</p>
                </div>
                
                <div onClick={(e) => this.onMobileClick('exterior/exterior_paint')} className="tarion-cpg__svg-container">
                    <MobPaint />
                    <p>Exterior Paint</p>
                </div>
                <div onClick={(e) => this.onMobileClick('exterior/roof')} className="tarion-cpg__svg-container">
                    <MobRoof />
                    <p>Roof</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container