/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import WithMobile from '../WithMobile';
import RImage from '../../images/exterior_rooms/Roof.svg';

import MobRoof from '../../images/mobile_svgs/Roof.svg';
import MobEaves from '../../images/mobile_svgs/eavestroughs.svg';
import MobChimney from '../../images/mobile_svgs/chimney.svg';
import MobShingles from '../../images/mobile_svgs/shingles.svg';
import MobSkylight from '../../images/mobile_svgs/skylight.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['chimney', false], ['eavestroughs', false], ['skylight', false], ['shingles', false],
    ['roofs', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "roof/chimney",
        title: "Chimney",
        class: "chimney",
        highlight_mode: 2
    },
    {
        path: "roof/shingles",
        title: "Shingles",
        class: "shingles",
        highlight_mode: 2
    },
    {
        path: "roof/roofs",
        title: "Roof",
        class: "roofs",
        highlight_mode: 2
    },
    {
        path: "roof/eavestroughs",
        title: "Eavestroughs",
        class: "eavestroughs",
        highlight_mode: 2
    },
    {
        path: "roof/skylight",
        title: "Skylight",
        class: "skylight",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "roof/" + item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <RImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">                
                <div onClick={(e) => this.onMobileClick('roof/shingles')} className="tarion-cpg__svg-container">
                    <MobShingles />
                    <p>Shingles</p>
                </div>
                <div onClick={(e) => this.onMobileClick('roof/roofs')} className="tarion-cpg__svg-container">
                    <MobRoof />
                    <p>Roof</p>
                </div>                
                <div onClick={(e) => this.onMobileClick('roof/skylight')} className="tarion-cpg__svg-container">
                    <MobSkylight />
                    <p>Skylight</p>
                </div>
                <div onClick={(e) => this.onMobileClick('roof/eavestroughs')} className="tarion-cpg__svg-container">
                    <MobEaves />
                    <p>Eavestroughs</p>
                </div>
                <div onClick={(e) => this.onMobileClick('roof/chimney')} className="tarion-cpg__svg-container">
                    <MobChimney />
                    <p>Chimney</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container