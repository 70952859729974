/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import WithMobile from '../WithMobile';
import CImage from '../../images/condo_rooms/CondoBedroom.svg';

import MobElectrical from '../../images/mobile_svgs/electrical.svg';
import MobClimateControl from '../../images/mobile_svgs/air-filtration.svg';
import MobTrim from '../../images/mobile_svgs/trim.svg';
import MobFinFloor from '../../images/mobile_svgs/flooring.svg';
import MobCeiling from '../../images/mobile_svgs/ceiling.svg';
import MobFinWall from '../../images/mobile_svgs/walls.svg';
import MobInteriorDoors from '../../images/mobile_svgs/interior-door.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['climate_control', false], ['electrical', false], ['trim', false], ['flooring', false],
    ['ceiling', false], ['wall', false], ['interior_doors', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "bedroom/electrical",
        title: "Electrical",
        class: "electrical",
        highlight_mode: 2
    },
    {
        path: "bedroom/climate_control",
        title: "Interior Climate Control",
        class: "climate_control",
        highlight_mode: 2
    },
    {
        path: "bedroom/interior_doors",
        title: "Interior Doors",
        class: "interior_doors",
        highlight_mode: 2
    },
    {
        path: "bedroom/trim",
        title: "Trim",
        class: "trim",
        highlight_mode: 2
    },
    {
        path: "bedroom/flooring",
        title: "Flooring",
        class: "flooring",
        highlight_mode: 2
    },
    {
        path: "bedroom/ceiling",
        title: "Ceiling",
        class: "ceiling",
        highlight_mode: 2
    },
    {
        path: "bedroom/wall",
        title: "Wall",
        class: "wall",
        highlight_mode: 2
    },
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }        
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for(let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "bedroom/" + item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <CImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('bedroom/electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/climate_control')} className="tarion-cpg__svg-container">
                    <MobClimateControl />
                    <p>Climate Control</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/trim')} className="tarion-cpg__svg-container">
                    <MobTrim />
                    <p>Trim</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/flooring')} className="tarion-cpg__svg-container">
                    <MobFinFloor />
                    <p>Flooring</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/ceiling')} className="tarion-cpg__svg-container">
                    <MobCeiling />
                    <p>Ceiling</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/wall')} className="tarion-cpg__svg-container">
                    <MobFinWall />
                    <p>Wall</p>
                </div>
                <div onClick={(e) => this.onMobileClick('bedroom/interior_doors')} className="tarion-cpg__svg-container">
                    <MobInteriorDoors />
                    <p>Interior Doors</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}

export default Container