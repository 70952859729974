/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import WithMobile from '../WithMobile';
import GImage from '../../images/exterior_rooms/Garage.svg';

import MobElectrical from '../../images/mobile_svgs/electrical.svg';
import MobGarage from '../../images/mobile_svgs/garage-door.svg';
import MobFloor from '../../images/mobile_svgs/flooring.svg';
import MobExteriorDoors from '../../images/mobile_svgs/exterior-door.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['exterior_doors', false], ['exterior_doors_man', false], ['doors', false], 
    ['garage_floor', false], ['cold_electrical', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "garage/garage_floor",
        title: "Garage Floor",
        class: "garage_floor",
        highlight_mode: 2
    },
    {
        path: "garage/exterior_doors",
        title: "Doors",
        id: "exterior_doors2",
        mode_selected: true,
        class: "exterior_doors exterior_doors_man",
        highlight_mode: 2
    },
    {
        path: "garage/exterior_doors",
        title: "Exterior Doors",
        id: "exterior_doors2",
        mobile_show: true,
        class: "exterior_doors exterior_doors_man",
        highlight_mode: 2
    },
    {
        path: "garage/exterior_doors_man",
        title: "Garage & Man Doors",
        id: "exterior_doors2",
        mobile_show: true,
        class: "exterior_doors exterior_doors_man",
        highlight_mode: 2
    },
    {
        path: "garage/cold_electrical",
        title: "Electrical",
        class: "cold_electrical",
        highlight_mode: 2
    },
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                {
                    this.setState({ redirect: true, redirectTo: "garage/" + item });
                    break;
                }
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <GImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                
                <div onClick={(e) => this.onMobileClick('garage/exterior_doors_man')} className="tarion-cpg__svg-container">
                    <MobGarage />
                    <p>Garage & Man Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garage/garage_floor')} className="tarion-cpg__svg-container">
                    <MobFloor />
                    <p>Garage Floor</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garage/exterior_doors')} className="tarion-cpg__svg-container">
                    <MobExteriorDoors />
                    <p>Exterior Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('garage/cold_electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>
            </div>;        
        
        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container