/* eslint import/no-webpack-loader-syntax: off */

import React from 'react'
import WithMobile from '../WithMobile'
import KImage from '../../images/Interior_rooms/Kitchen.svg';

import MobElectrical from '../../images/mobile_svgs/electrical.svg';
import MobCabinet from '../../images/mobile_svgs/cabinets.svg';
import MobCounters from '../../images/mobile_svgs/countertop.svg';
import MobClimateControl from '../../images/mobile_svgs/air-filtration.svg';
import MobTrim from '../../images/mobile_svgs/trim.svg';
import MobFinFloor from '../../images/mobile_svgs/flooring.svg';
import MobCeiling from '../../images/mobile_svgs/ceiling.svg';
import MobFinWall from '../../images/mobile_svgs/walls.svg';
import MobWindows from '../../images/mobile_svgs/window.svg';
import MobInteriorDoors from '../../images/mobile_svgs/interior-door.svg';
import MobExteriorDoors from '../../images/mobile_svgs/exterior-door.svg';
import MobPlumbing from '../../images/mobile_svgs/sink.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['cabinets', false], ['climate_control', false], ['countertops', false], ['electrical', false], ['trim', false], ['plumbing', false],
    ['interior_doors', false], ['exterior_doors', false],
    ['flooring', false], ['ceiling', false], ['wall', false], ['windows', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "kitchen/cabinets",
        title: "Cabinets",
        class: "cabinets",
        highlight_mode: 2
    },
    {
        path: "kitchen/countertops",
        title: "Countertops",
        class: "countertops",
        highlight_mode: 2
    },
    {
        path: "kitchen/climate_control",
        title: "Interior Climate Control",
        class: "climate_control",
        highlight_mode: 2
    },
    {
        path: "kitchen/electrical",
        title: "Electrical",
        class: "electrical",
        highlight_mode: 2
    },
    {
        path: "kitchen/trim",
        title: "Trim",
        class: "trim",
        highlight_mode: 2
    },
    {
        path: "kitchen/plumbing",
        title: "Plumbing",
        class: "plumbing",
        highlight_mode: 2
    },
    {
        path: "kitchen/doors",
        title: "Doors",
        id: "interior_doors2",
        mode_selected: true,
        class: "interior_doors exterior_doors",
        highlight_mode: 2
    },
    {
        path: "kitchen/doors_exterior",
        title: "Exterior Doors",
        id: "interior_doors2",
        mobile_show: true,
        class: "interior_doors exterior_doors",
        highlight_mode: 2
    },
    {
        path: "kitchen/doors_interior",
        title: "Interior Doors",
        id: "interior_doors2",
        mobile_show: true,
        class: "interior_doors exterior_doors",
        highlight_mode: 2
    },
    {
        path: "kitchen/flooring",
        title: "Flooring",
        class: "flooring",
        highlight_mode: 2
    },
    {
        path: "kitchen/ceiling",
        title: "Ceiling",
        class: "ceiling",
        highlight_mode: 2
    },
    {
        path: "kitchen/wall",
        title: "Wall",
        class: "wall",
        highlight_mode: 2
    },
    {
        path: "kitchen/windows",
        title: "Windows",
        class: "windows",
        highlight_mode: 2
    },
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }        
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes,Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item,Colors))
                    this.setState({ redirect: true, redirectTo: "kitchen/" + item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="pointer tarion-cpg__svg-container">
                    <KImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('kitchen/cabinets')} className="tarion-cpg__svg-container">
                    <MobCabinet />
                    <p>Cabinets</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/countertops')} className="tarion-cpg__svg-container">
                    <MobCounters />
                    <p>Countertops</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/windows')} className="tarion-cpg__svg-container">
                    <MobWindows />
                    <p>Windows</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/electrical')} className="tarion-cpg__svg-container">
                    <MobElectrical />
                    <p>Electrical</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/climate_control')} className="tarion-cpg__svg-container">
                    <MobClimateControl />
                    <p>Climate Control</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/trim')} className="tarion-cpg__svg-container">
                    <MobTrim />
                    <p>Trim</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/flooring')} className="tarion-cpg__svg-container">
                    <MobFinFloor />
                    <p>Flooring</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/ceiling')} className="tarion-cpg__svg-container">
                    <MobCeiling />
                    <p>Ceiling</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/wall')} className="tarion-cpg__svg-container">
                    <MobFinWall />
                    <p>Wall</p>
                </div>
                
                <div onClick={(e) => this.onMobileClick('kitchen/doors_interior')} className="tarion-cpg__svg-container">
                    <MobInteriorDoors />
                    <p>Interior Doors</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/plumbing')} className="tarion-cpg__svg-container">
                    <MobPlumbing />
                    <p>Plumbing</p>
                </div>
                <div onClick={(e) => this.onMobileClick('kitchen/doors_exterior')} className="tarion-cpg__svg-container">
                    <MobExteriorDoors />
                    <p>Exterior Doors</p>
                </div>
                
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}



export default Container