/* eslint import/no-webpack-loader-syntax: off */

import React from 'react';
import WithMobile from '../WithMobile';

//import LImage from '../../images/exterior_rooms/Landscape.svg';
import LImage from '../../images/exterior_rooms/Landscape.min.svg';

import MobPlumbing from '../../images/mobile_svgs/plumbing.svg';
import MobLandscape from '../../images/mobile_svgs/landscape.svg';
import MobDeck from '../../images/mobile_svgs/deck.svg';
import MobDriveway from '../../images/mobile_svgs/driveway.svg';

import { Redirect } from 'react-router';
import * as functions from '../functions';

const Colors = [
    ['landscape', false], ['decks', false], ['plumbing', false], ['driveway', false]
];
const title = "Select an area"
const navItems = [
    {
        path: "landscape_deck/driveway",
        title: "Driveway",
        class: "driveway",
        highlight_mode: 2
    },
    {
        path: "landscape_deck/decks",
        title: "Decks",
        class: "decks",
        highlight_mode: 2
    },
    {
        path: "landscape_deck/landscape",
        title: "Landscape",
        class: "landscape",
        highlight_mode: 2
    },
    {
        path: "landscape_deck/plumbing",
        title: "Plumbing",
        class: "plumbing",
        highlight_mode: 2
    }
]

class Container extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            redirect: false,
            redirectTo: ""
        }
        this.updatePredicate = this.updatePredicate.bind(this);
    }

    componentDidMount()
    {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }
    componentWillUnmount() { window.removeEventListener("resize", this.updatePredicate); }
    updatePredicate() { this.setState({ isDesktop: window.innerWidth > 980 }); }

    onMouseClick(e)
    {
        e.persist();
        let classes = null;
        if (!e.target.className || !e.target.className.baseVal) { }
        else
            classes = e.target.className.baseVal;
        if (classes && functions.default.findColors(classes, Colors))
        {
            let temp = classes.split(" ");
            for (let item of temp)
                if (functions.default.findColors(item, Colors))
                    this.setState({ redirect: true, redirectTo: "landscape_deck/" + item });
        }
    }

    onMobileClick(item) { this.setState({ redirect: true, redirectTo: item }); }

    render()
    {
        let showBackButton = true;
        let SVG = this.state.isDesktop ?
            <div className="tarion-cpg__container">
                <div onMouseMove={((e) => functions.default.onMove(e, Colors))} onClick={(e) => this.onMouseClick(e)} className="tarion-cpg__svg-container pointer">
                    <LImage />
                </div>
            </div>
            :
            <div className="tarion-cpg__mobile-container small">
                <div onClick={(e) => this.onMobileClick('landscape_deck/driveway')} className="tarion-cpg__svg-container">
                    <MobDriveway />                                        
                    <p>Driveway</p>
                </div>
                <div onClick={(e) => this.onMobileClick('landscape_deck/decks')} className="tarion-cpg__svg-container">
                    <MobDeck />       
                    <p>Decks</p>
                </div>
                <div onClick={(e) => this.onMobileClick('landscape_deck/landscape')} className="tarion-cpg__svg-container">
                    <MobLandscape />                 
                    <p>Landscape</p>
                </div>
                <div onClick={(e) => this.onMobileClick('landscape_deck/plumbing')} className="tarion-cpg__svg-container">
                    <MobPlumbing/>
                    <p>Plumbing</p>
                </div>
            </div>;

        let Component = null;
        if (this.state.redirect)
            Component = <Redirect push to={this.state.redirectTo} />
        else
            Component =
                <div className="tarion-cpg__container">
                    {SVG}
                </div>

        return (
            <WithMobile showDesktop={this.state.isDesktop} Component={Component} title={title} showBackButton={showBackButton} navItems={navItems} Colors={Colors} />
        )
    }
}

export default Container