/* eslint import/no-webpack-loader-syntax: off */
import Category from '../../Category'

import Int from '../../../images/mobile_svgs/condo/exterior.svg';
import Siding from '../../../images/mobile_svgs/Siding.svg';
import Column from '../../../images/mobile_svgs/Column.svg';
import Door from '../../../images/mobile_svgs/exterior-door.svg';
import Paint from '../../../images/mobile_svgs/Paint.svg';

const title = "Select Exterior Closure Category"
const navItems = [
    {
        path: "exterior_closure/general",
        title: "General",
        image: Int
    },
    {
        path: "exterior_closure/exterior_cladding",
        title: "Exterior Cladding",
        image: Siding
    },
    {
        path: "exterior_closure/exterior_columns",
        title: "Exterior Columns",
        image: Column
    },
    {
        path: "exterior_closure/exterior_doors",
        title: "Exterior Doors",
        image: Door
    },
    {
        path: "exterior_closure/exterior_paint",
        title: "Exterior Paint",
        image: Paint
    }
]

export default Category(title, navItems, true, false)