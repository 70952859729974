/*eslint eqeqeq: 0*/

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import A4_Image from '../../../images/appendix_a4.jpg'
import A5_Image from '../../../images/appendix_a5.jpg'

class Article extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            info_bottom: 0,
            article_bottom: 0
        }
    }

    render()
    {
        let data = null;

        if(this.props.appendix === "A2")
        {
            data = <div className="article_data">
                        <div id="article_info" className={`article_info appendix
                                ${this.state.article_bottom > this.state.info_bottom ? '' : 'height_100'}
                            `}
                        >
                            <h3>Appendix A2</h3>
                            <h4>Moisture in Wood and Laminate Floor</h4>
                            <p>
                                The National Wood Flooring Association and the North American Laminate Flooring
                                Association reference the following relative humidity readings for their products.
                                </p>
                            <p>
                                This information is intended as a guide only.
                                </p>
                            <p>
                                WOOD AND LAMINATE FLOORING COMFORT LEVELS
                                </p>
                            <p>
                                Wood and laminate flooring will perform best when the interior environment is controlled
                                to stay within a relative humidity range of 30 to 50 per cent and a temperature range
                                between 15°C and 27°C. Fortunately, that’s about the same comfort range most humans
                                enjoy. The chart below indicates the moisture content at any given combination of
                                temperature and humidity. Note that equilibrium moisture contents in the recommended
                                temperature/humidity range (shaded area) coincide with the 6 to 9 per cent range within
                                which most hardwood/laminate flooring is manufactured. Although some movement can
                                be expected even between 6 and 9 per cent, wood/laminate can expand and shrink
                                dramatically outside that range.
                                </p>
                            <div id="last_p_article_info" className="appendix_table a2">
                                <h4>Moisture Content of Wood</h4>
                                <h5>At various temperatures and relative humidity readings</h5>
                                <table>
                                    <thead>
                                        <tr>
                                            <td colSpan="21">Temperature (Celsuis)</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="bold">-2</td>
                                            <td>1.4</td>
                                            <td>2.6</td>
                                            <td>3.7</td>
                                            <td>4.6</td>
                                            <td>5.5</td>
                                            <td>6.3</td>
                                            <td>7.1</td>
                                            <td>7.9</td>
                                            <td>8.7</td>
                                            <td>9.5</td>
                                            <td>10.4</td>
                                            <td>11.3</td>
                                            <td>12.4</td>
                                            <td>13.5</td>
                                            <td>14.9</td>
                                            <td>16.5</td>
                                            <td>18.5</td>
                                            <td>21.0</td>
                                            <td>24.3</td>
                                            <td>26.9</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">4</td>
                                            <td>1.4</td>
                                            <td>2.6</td>
                                            <td>3.7</td>
                                            <td>4.6</td>
                                            <td>5.5</td>
                                            <td>6.3</td>
                                            <td>7.1</td>
                                            <td>7.9</td>
                                            <td>8.7</td>
                                            <td>9.5</td>
                                            <td>10.4</td>
                                            <td>11.3</td>
                                            <td>12.4</td>
                                            <td>13.5</td>
                                            <td>14.9</td>
                                            <td>16.5</td>
                                            <td>18.5</td>
                                            <td>21.0</td>
                                            <td>24.3</td>
                                            <td>26.9</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">10</td>
                                            <td>1.4</td>
                                            <td>2.6</td>
                                            <td>3.7</td>
                                            <td>4.6</td>
                                            <td>5.5</td>
                                            <td>6.3</td>
                                            <td>7.1</td>
                                            <td>7.9</td>
                                            <td>8.7</td>
                                            <td>9.5</td>
                                            <td>10.4</td>
                                            <td>11.3</td>
                                            <td>12.4</td>
                                            <td>13.5</td>
                                            <td>14.9</td>
                                            <td>16.5</td>
                                            <td>18.5</td>
                                            <td>21.0</td>
                                            <td>24.3</td>
                                            <td>26.9</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">15</td>
                                            <td>1.3</td>
                                            <td>2.5</td>
                                            <td>3.6</td>
                                            <td>4.6</td>
                                            <td>5.4</td>
                                            <td className="shaded">6.2</td>
                                            <td className="shaded">7.0</td>
                                            <td className="shaded">7.8</td>
                                            <td className="shaded">8.6</td>
                                            <td className="shaded">9.4</td>
                                            <td>10.2</td>
                                            <td>11.1</td>
                                            <td>12.1</td>
                                            <td>13.3</td>
                                            <td>14.6</td>
                                            <td>16.2</td>
                                            <td>18.2</td>
                                            <td>20.7</td>
                                            <td>24.1</td>
                                            <td>26.8</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">21</td>
                                            <td>1.3</td>
                                            <td>2.5</td>
                                            <td>3.5</td>
                                            <td>4.5</td>
                                            <td>5.4</td>
                                            <td className="shaded">6.2</td>
                                            <td className="shaded">6.9</td>
                                            <td className="shaded">7.7</td>
                                            <td className="shaded">8.5</td>
                                            <td className="shaded">9.2</td>
                                            <td>10.1</td>
                                            <td>11.0</td>
                                            <td>12.0</td>
                                            <td>13.1</td>
                                            <td>14.4</td>
                                            <td>16.0</td>
                                            <td>17.9</td>
                                            <td>20.5</td>
                                            <td>23.9</td>
                                            <td>26.6</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">27</td>
                                            <td>1.3</td>
                                            <td>2.4</td>
                                            <td>3.5</td>
                                            <td>4.4</td>
                                            <td>5.3</td>
                                            <td className="shaded">6.1</td>
                                            <td className="shaded">6.8</td>
                                            <td className="shaded">7.6</td>
                                            <td className="shaded">8.3</td>
                                            <td className="shaded">9.1</td>
                                            <td>9.9</td>
                                            <td>10.6</td>
                                            <td>11.7</td>
                                            <td>12.9</td>
                                            <td>14.2</td>
                                            <td>15.7</td>
                                            <td>17.7</td>
                                            <td>20.2</td>
                                            <td>23.6</td>
                                            <td>26.3</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">32</td>
                                            <td>1.2</td>
                                            <td>2.3</td>
                                            <td>3.4</td>
                                            <td>4.3</td>
                                            <td>5.1</td>
                                            <td>5.9</td>
                                            <td>6.7</td>
                                            <td>7.4</td>
                                            <td>8.1</td>
                                            <td>8.9</td>
                                            <td>9.7</td>
                                            <td>10.5</td>
                                            <td>11.5</td>
                                            <td>12.6</td>
                                            <td>13.9</td>
                                            <td>15.4</td>
                                            <td>17.3</td>
                                            <td>19.8</td>
                                            <td>23.3</td>
                                            <td>26.0</td>
                                        </tr>
                                        <tr>
                                            <td className="bold">38</td>
                                            <td>1.2</td>
                                            <td>2.3</td>
                                            <td>3.3</td>
                                            <td>4.2</td>
                                            <td>5.0</td>
                                            <td>5.8</td>
                                            <td>6.5</td>
                                            <td>7.2</td>
                                            <td>7.9</td>
                                            <td>8.7</td>
                                            <td>9.5</td>
                                            <td>10.3</td>
                                            <td>11.2</td>
                                            <td>12.3</td>
                                            <td>13.6</td>
                                            <td>15.1</td>
                                            <td>17.0</td>
                                            <td>19.5</td>
                                            <td>22.9</td>
                                            <td>25.6</td>
                                        </tr>
                                        <tr className="bold">
                                            <td>&nbsp;</td>
                                            <td>5</td>
                                            <td>10</td>
                                            <td>15</td>
                                            <td>20</td>
                                            <td>25</td>
                                            <td>30</td>
                                            <td>35</td>
                                            <td>40</td>
                                            <td>45</td>
                                            <td>50</td>
                                            <td>55</td>
                                            <td>60</td>
                                            <td>65</td>
                                            <td>70</td>
                                            <td>75</td>
                                            <td>80</td>
                                            <td>85</td>
                                            <td>90</td>
                                            <td>95</td>
                                            <td>98</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="21">Relative Humidity (%)</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    Information taken from NWFA’s Technical Publication No. A100 &amp; as per NALFA’s recommendation.
                                </p>
                            </div>
                        </div>
                    </div>
        }
        else if(this.props.appendix === "A3")
        {
            data = <div className="article_data">
                <div id="article_info" className={`article_info appendix
                            ${this.state.article_bottom > this.state.info_bottom ? '' : 'height_100'}
                        `}
                >
                    <h3>Appendix A3</h3>
                    <h4>Moisture and Windows</h4>
                    <p>
                        This chart identifies the maximum relative humidity for a given inside temperature above
                        which condensation will form on windows. For additional information, see the following
                        guides:&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="https://www.cmhc-schl.gc.ca/en/co/grho/moaiprre/">“Moisture and Air, Problems and Remedies” by Canada Mortgage and Housing Corporation</a>
                        , or&nbsp;
                        <a target="_blank" rel="noopener noreferrer" href="http://www.nrcan.gc.ca/energy/products/categories/fenestration/13739">“Fenestration Products: Condensation and Humidity” by Natural Resources Canada</a>
                        .
                    </p>
                    <div id="last_p_article_info" className="appendix_table borders a3">
                        <table>
                            <tr className="bold">
                                <td rowSpan="4">Outside Temperature (&deg;C)</td>
                                <td colSpan="6">Inside Temperature</td>
                            </tr>
                            <tr className="bold">
                                <td className="shaded" colSpan="2">20&deg;C"</td>
                                <td colSpan="2">20&deg;C"</td>
                                <td className="shaded" colSpan="2">20&deg;C"</td>
                            </tr>
                            <tr className="bold">
                                <td className="doubled_border" colSpan="7">Maximum Relative Humidity</td>
                            </tr>
                            <tr className="bold uppercase">
                                <td>Single Glass</td>
                                <td>Double Glass</td>
                                <td>Single Glass</td>
                                <td>Double Glass</td>
                                <td>Single Glass</td>
                                <td>Double Glass</td>
                            </tr>
                            <tr>
                                <td>-35</td>
                                <td className="shaded">3</td>
                                <td className="shaded">18</td>
                                <td>3</td>
                                <td>18</td>
                                <td className="shaded">3</td>
                                <td className="shaded">18</td>
                            </tr>
                            <tr>
                                <td>-29</td>
                                <td className="shaded">5</td>
                                <td className="shaded">23</td>
                                <td>5</td>
                                <td>22</td>
                                <td className="shaded">5</td>
                                <td className="shaded">21</td>
                            </tr>
                            <tr>
                                <td>-23</td>
                                <td className="shaded">8</td>
                                <td className="shaded">27</td>
                                <td>7</td>
                                <td>26</td>
                                <td className="shaded">6</td>
                                <td className="shaded">25</td>
                            </tr>
                            <tr>
                                <td>-18</td>
                                <td className="shaded">12</td>
                                <td className="shaded">33</td>
                                <td>11</td>
                                <td>31</td>
                                <td className="shaded">10</td>
                                <td className="shaded">29</td>
                            </tr>
                            <tr>
                                <td>-12</td>
                                <td className="shaded">17</td>
                                <td className="shaded">39</td>
                                <td>16</td>
                                <td>37</td>
                                <td className="shaded">15</td>
                                <td className="shaded">35</td>
                            </tr>
                            <tr>
                                <td>-7</td>
                                <td className="shaded">24</td>
                                <td className="shaded">34</td>
                                <td>23</td>
                                <td>44</td>
                                <td className="shaded">22</td>
                                <td className="shaded">42</td>
                            </tr>
                            <tr>
                                <td>-1</td>
                                <td className="shaded">34</td>
                                <td className="shaded">55</td>
                                <td>32</td>
                                <td>52</td>
                                <td className="shaded">30</td>
                                <td className="shaded">49</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        }
        else if(this.props.appendix === "A4")
        {
            let beep = <img src={A4_Image} alt="img" className="img" />;
            data = <div className="article_data">
                <div id="article_info" className={`article_info appendix a4
                            ${this.state.article_bottom > this.state.info_bottom ? '' : 'height_100'}
                        `}
                >
                    <h3>Appendix A4</h3>
                    <h4>Measuring Variation from the Specified Plane Using a Plane of Reference</h4>
                    <h5>4.1 To measure the variation:</h5>
                    <p>
                        1) Place two blocks of equal thickness on either side of the surface you are considering.<br/>
                        2) Stretch a string across the top of the blocks so the string doesn’t sag; the blocks may
                        have to be secured from moving.<br/>
                        3) Measure the difference between the string and the actual surface and subtract the
                        thickness of the blocks.<br/>
                        4) Compare the result with the allowable variation in the Guidelines. 
                    </p>
                    <figure>
                        {beep}
                        <figcaption>Figure 1. Measuring actual surface variation from a <i> specified plane </i> using a plane of reference.</figcaption>
                    </figure>
                    <h5>4.2 Approximate width measurements specified in this document:</h5>
                    <p>In order to quickly assess whether a defect is within the specified tolerances, the following Canadian coins may be used to approximate measurements of variation.</p>
                    <div id="last_p_article_info" className="appendix_table borders">
                        <table>
                            <tr>
                                <td>Specified dimensions in CPG:</td>
                                <td>For an approximate measurement use thickness/edge of:</td>
                            </tr>
                            <tr>
                                <td>1 mm</td>                                
                                <td>One dime</td>
                            </tr>
                            <tr>
                                <td>1.5 mm</td>
                                <td>One quarter</td>
                            </tr>
                            <tr>
                                <td>2 mm</td>
                                <td>One loonie</td>
                            </tr>
                        </table>
                        <p className="no_margin small">Source: Royal Canadian Mint</p>
                    </div>
                </div>
            </div>
        }
        else if (this.props.appendix === "A5")
        {
            data = <div className="article_data">
                <div id="article_info" className={`article_info appendix a5
                            ${this.state.article_bottom > this.state.info_bottom ? '' : 'height_100'}
                        `}
                >
                    <h3>Appendix A5</h3>
                    <h4>How to Conduct a Water Test</h4>
                    <p>
                       A water test shall be performed to confirm water leaks, both above and below grade.
                        Above grade the intent is to simulate an average, wind-driven rainfall but should never be
                        done using full pressure in a single-stream or pressure-altering device such as a pressure
                        washer. This can force water through building assemblies and flashings not intended for
                        high-volume or high-pressure water saturation. Below grade the intent is to simulate
                        natural water flow around a foundation caused by rain or snow melting where the water
                        may inadvertently be directed towards the foundation wall. Water penetration is
                        considered to be bulk water coming into the basement or accumulating near the point of
                        entry, or dampness on the wall appearing as a result of the test, but excludes dampness
                        caused by condensation or other causes. 
                    </p>
                    <h5>5.1 Above Grade</h5>
                    <p>
                       Use a standard garden hose and sprayer attachment. The sprayer attachment should be
                        set on “shower” or other similar dispersal pattern. Spray the area to be tested for not
                        more than 10 minutes from a minimum distance of 2 m. Have another person checking
                        inside for the point of origin and the length of time it takes for water to appear. Areas to
                        be investigated should be kept dry prior to the test. 
                    </p>
                    <h5>5.2 Below Grade</h5>
                    <p id="last_p_article_info">
                        Use a standard garden hose with no attachments. The hose bib should be set at about
                        half flow to simulate melting snow or rainfall. The water from the hose is to be directed
                        along the face of the foundation to allow the water to run parallel to the wall, at grade,
                        finding its own way down the exterior of the wall to the perimeter foundation drains. Run
                        the water for not more than 20 minutes checking periodically for water penetration.
                        Identify the location and the point of entry of any water (crack, tie rod, snap tie,
                        honeycombing) and the length of time it takes for water to appear. 
                    </p>
                </div>
            </div>
        }
        else if (this.props.appendix === "A6")
        {
            let beep = <img src={A5_Image} alt="Chipped Bricks"/>
            data = <div className="article_data">
                <div id="article_info" className={`article_info appendix
                            ${this.state.article_bottom > this.state.info_bottom ? '' : 'height_100'}
                        `}
                >
                    <h3>Appendix A6</h3>
                    <h4>Chipped Clay Bricks</h4>
                    <p>
                        The following table is from Canadian Standards Association, CSA-A82-06, “Fired
                        Masonry Brick made from Clay or Shale”.
                    </p>
                    <div className="appendix_table borders a6">
                        <table>
                            <tr className="bold">
                                <td colSpan="7">
                                    <b>Fixed Masonry Brickmade from Clay or Shale</b><br/>
                                    Maximum Permissible Extent<sup>1</sup> of Chippage from the<br/>
                                    Edges and Corners of the Finished Face or Faces onto the Surface
                                </td>
                            </tr>
                            <tr className="bold">
                                <td rowSpan="2">Brick Type (1)</td>
                                <td rowSpan="2">Maximum % allowed* (2)</td>
                                <td colSpan="2">Chippage (in millimeters) in from:</td>
                                <td rowSpan="2">Remaining % allowed* (5)</td>
                                <td colSpan="2">Chippage (in millimeters) in from:</td>
                            </tr>
                            <tr className="bold">
                                <td>Edge (3)</td>
                                <td>Corner (4)</td>
                                <td>Edge (6)</td>
                                <td>Corner (7)</td>
                            </tr>
                            <tr className="shaded_red_fading">
                                <td>S<sup>2</sup> (plain)&#x271D;</td>
                                <td>10% or less</td>
                                <td>6.5 - 8.0</td>
                                <td>9.5 - 13.0</td>
                                <td>90 - 100%</td>
                                <td>0 - 6.5</td>
                                <td>0 - 9.5</td>
                            </tr>
                            <tr className="shaded_blue_fading">
                                <td>S<sup>2</sup> (textured)&#x273B;</td>
                                <td>15% or less</td>
                                <td>8.0 - 11.0</td>
                                <td>13.0 - 19.0</td>
                                <td>85 - 100%</td>
                                <td>0 - 8.0</td>
                                <td>0 - 13.0</td>
                            </tr>
                        </table>
                        <p className="small no_margin">&#x271D; Plain brick are dry-pressed brick or extruded brick with an unbroken natural die finish.</p> 
                        <p className="small no_margin">
                            &#x273B; Texture brick are moulded brick or extruded brick with the face sanded, combed, engobed, scratched, or scarified, or the
                            die skin on the face entirely broken by mechanical means such as wire cutting or wire brushing.
                        </p> 
                        <p className="small no_margin">
                            * Percentage of exposed brick in the wall permitted to have chippage extending from an edge or corner to the dimensions
                            in this Table. Of all the brick units that will be exposed in the wall, a small percentage of the units may have chips that
                            range in size greater than that allowed for the majority of the units. This special allowed percentage, as shown in the
                            “Maximum % allowed” column (2), ranges up to 10% for S (plain), and up to 15% for S (textured). The remainder of the
                            brick units that will be exposed in the wall, as shown in the “Remaining % allowed” column (5), shall conform to the
                            maximum allowed chippage shown in columns (6) and (7) of this Table. 
                        </p>
                        <p className="small">
                            <b>Example:</b> The brick units to be placed in to the wall should be inspected prior to being placed. The S (plain) units will then
                            conform to the requirements of this Table if not more that 10% of the units have edge chips greater than 6.5 mm but less
                            than 8.0 mm and the remainder of the units, in this example 90% (100% - 10%), do not have edge chips greater than 6.5
                            mm in from the edge or 9.5 mm in from the corner. 
                        </p>
                        <p className="small">
                            <b>Notes:</b><br/>
                            1.     The aggregate length of chips shall not exceed 10% of the perimeter of the face of the brick (CAN/CSA- A82-06, s.7.3).<br/>
                            2.     Type S brick are for general use in masonry (CAN/CSA-A82-06, s.5.(1))
                        </p>
                        <p className="small light_bold">
                            “With the permission of Canadian Standards Association, material is reproduced from CSA Standard <b> CAN/CSA-A82-06</b>,
                            which is copyrighted by Canadian Standards Association, 5060 Spectrum Way, Suite 100
                            Mississauga, Ontario, L4W 5N6, <a href="www.csa.ca" target="_blank"><b>www.csa.ca</b></a>. While use of this material has been authorized, CSA shall not be
                            responsible for the manner in which the information is presented, nor for any interpretations thereof.” 
                        </p>
                    </div>
                    <h5>6.1 Using a representative sample to determine the percentages of affected bricks in Table 4.</h5>
                    <p>A representative sample may be used to determine the percentage of bricks (Columns (2) and (5) in Table 4) that have chips of specified sizes.</p>
                    <p>
                        The representative sample (versus an isolated section of the wall with the chipped brick
                        problem) shall be 1 m x 1 m in size with the centre of the sample located 1.5 m above the
                        foundation in the middle of the wall. If the sample lands on an opening (window, door,
                        etc), it shall be moved horizontally in the direction of the most brick in the sample so a full
                        square metre of brick is sampled. 
                    </p>
                    <p>Individual bricks must still meet the maximum chip dimensions in Table 4.</p>

                    <h5>6.2 Illustrating Brick Chippage</h5>
                    <p id="last_p_article_info">These illustrations are colour-coded to help describe the information in Table 4.<br/>
                       {beep} 
                    </p>
                </div>
            </div>
        }
        else
        {
            data = <p>No Appendix Found</p>
        }

        return (data)
    }
}

export default withRouter(Article)
