import React from 'react'
import Sidebar from './Sidebar'
import BackButton from './BackButton'
import { Link } from 'react-router-dom'
import * as functions from './functions';

const WithSidebar = ({
  Component,
  title = null,
  showBackButton = true,
  navItems = [],
  Colors = [],
  showArticleLink = false,
  Paragraph = false
}) => (
  <div className="tarion-cpg__with-sidebar">
    <div className="tarion-cpg__sidebar-container">
        {showBackButton && <BackButton/>}
        {title && <h2 className="tarion-cpg__sidebar-header">{title}</h2>}
        <Sidebar hoverItems={Colors} navItems={navItems} showArticleLink={showArticleLink}/>
        {Paragraph ? Paragraph : ""}
    </div>
    <div className="tarion-cpg__main-container">
      {Component}
    </div>

    <div id="basement_unfinished" className="modal">
        <p className="basement_unfinished" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="basement_finished" to={"interior/basement_finished"}>
                Finished
            </Link>
        </p>
        <p className="basement_unfinished" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="basement_unfinished" to={"interior/basement_unfinished"}>
                Unfinished
            </Link>
        </p>
    </div>
    <div id="interior_doors" className="modal">
        <p className="interior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors" to={"living_room/doors_exterior"}>
                Exterior Doors
            </Link>
        </p>
        <p className="interior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="interior_doors" to={"living_room/doors_interior"}>
                Interior Doors
            </Link>
        </p>
    </div>
    <div id="exterior_doors" className="modal">
        <p className="exterior_doors_man" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors_man" to={"exterior/exterior_doors_man"}>
                Garage & Man Doors
            </Link>
        </p>
        <p className="exterior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors" to={"exterior/exterior_doors"}>
                Exterior Doors
            </Link>
        </p>
    </div>
    <div id="exterior_doors3" className="modal">
        <p className="exterior_doors_man" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors_man" to={"townhome/building_exterior/exterior_doors_man"}>
                Garage & Man Doors
            </Link>
        </p>
        <p className="exterior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors" to={"townhome/building_exterior/exterior_doors"}>
                Exterior Doors
            </Link>
        </p>
    </div>
    <div id="exterior_doors2" className="modal">
        <p className="exterior_doors_man" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors_man" to={"garage/exterior_doors_man"}>
                Garage & Man Doors
            </Link>
        </p>
            <p className="exterior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors" to={"garage/exterior_doors"}>
                Exterior Doors
            </Link>
        </p>
    </div>
    <div id="interior_doors2" className="modal">
        <p className="exterior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="exterior_doors" to={"kitchen/doors_exterior"}>
                Exterior Doors
            </Link>
        </p>
        <p className="interior_doors" onMouseMove={((e) => functions.default.onMove(e, Colors))}>
            <Link className="interior_doors" to={"kitchen/doors_interior"}>
                Interior Doors
            </Link>
        </p>
    </div>
  </div>
)

export default WithSidebar